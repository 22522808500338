<template>
  <div class="task-container container mt-5">
    <h2 class="text-primary mb-4 fw-bold">{{ $t('tasks.title') }}</h2>

    <!-- Überfällige Aufgaben -->
    <div v-if="overdueTasks.length" class="task-section">
      <h4 class="text-danger">{{ $t('tasks.overdue') }}</h4>
      <div v-for="task in overdueTasks" :key="task.id" class="task-card task-overdue mb-3">
        <div class="task-content">
          <h5>{{ translateEntityType(task.entity_type) }}: {{ task.entity_title }}</h5>
          <p>{{ task.description }}</p>
          <p><strong>{{ $t('tasks.due_date') }}:</strong> {{ formatDate(task.deadline) }}</p>
          <div class="task-actions">
            <button class="btn btn-primary btn-sm me-2" @click="viewTask(task)">{{ $t('common.view') }}</button>
            <button class="btn btn-success btn-sm me-2" @click="completeTask(task.id)">{{ $t('common.complete') }}</button>
            <button class="btn btn-danger btn-sm me-2" @click="deleteTask(task.id)">{{ $t('common.delete') }}</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Anstehende Aufgaben -->
    <div v-if="upcomingTasks.length" class="task-section mt-5">
      <h4 class="text-primary">{{ $t('tasks.upcoming') }}</h4>
      <div v-for="task in upcomingTasks" :key="task.id" :class="['task-card', 'mb-3', taskClass(task.deadline)]">
        <div class="task-content">
          <h5>{{ translateEntityType(task.entity_type) }}: {{ task.entity_title }}</h5>
          <p>{{ task.description }}</p>
          <p><strong>{{ $t('tasks.due_date') }}:</strong> {{ formatDate(task.deadline) }}</p>
          <div class="task-actions">
            <button class="btn btn-primary btn-sm me-2" @click="viewTask(task)">{{ $t('common.view') }}</button>
            <button class="btn btn-success btn-sm me-2" @click="completeTask(task.id)">{{ $t('common.complete') }}</button>
            <button class="btn btn-danger btn-sm me-2" @click="deleteTask(task.id)">{{ $t('common.delete') }}</button>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="!overdueTasks.length && !upcomingTasks.length">
      <p class="text-muted">{{ $t('tasks.no_tasks') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tasks: [],
      overdueTasks: [],
      upcomingTasks: [],
    };
  },
  methods: {
    async fetchTasks() {
      const token = localStorage.getItem('authToken');
      try {
        const response = await fetch('https://kompaktcrm.de/beta/tasks_api.php', {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = await response.json();
        const today = new Date();

        this.tasks = data;

        // Sortiere überfällige Aufgaben
        this.overdueTasks = this.tasks
          .filter((task) => new Date(task.deadline) < today && !task.is_completed)
          .sort((a, b) => new Date(a.deadline) - new Date(b.deadline));

        // Sortiere anstehende Aufgaben
        this.upcomingTasks = this.tasks
          .filter((task) => new Date(task.deadline) >= today && !task.is_completed)
          .sort((a, b) => new Date(a.deadline) - new Date(b.deadline));
      } catch (error) {
        console.error(this.$t('tasks.error_loading'), error);
      }
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString();
    },
    taskClass(deadline) {
      const today = new Date();
      const dueDate = new Date(deadline);
      const diffTime = dueDate - today;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (dueDate < today) return 'task-overdue';
      if (diffDays <= 3) return 'task-soon';
      return 'task-normal';
    },
    async completeTask(taskId) {
      const token = localStorage.getItem('authToken');
      try {
        const response = await fetch('https://kompaktcrm.de/beta/tasks_api.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            action: 'completeTask',
            id: taskId,
          }),
        });
        const result = await response.json();
        if (result.success) {
          this.fetchTasks();
        } else {
          alert(this.$t('tasks.error') + ': ' + result.error);
        }
      } catch (error) {
        console.error(this.$t('tasks.error_completing'), error);
      }
    },
    async deleteTask(taskId) {
      const token = localStorage.getItem('authToken');
      if (confirm(this.$t('tasks.confirm_delete'))) {
        try {
          const response = await fetch(`https://kompaktcrm.de/beta/tasks_api.php?id=${taskId}`, {
            method: 'DELETE',
            headers: { Authorization: `Bearer ${token}` },
          });
          const result = await response.json();
          if (result.success) {
            this.fetchTasks();
          } else {
            alert(this.$t('tasks.error') + ': ' + result.error);
          }
        } catch (error) {
          console.error(this.$t('tasks.error_deleting'), error);
        }
      }
    },
    viewTask(task) {
      const routeName = `${task.entity_type.charAt(0).toUpperCase() + task.entity_type.slice(1)}View`;
      this.$router.push({ name: routeName, params: { id: task.entity_id } });
    },
    translateEntityType(entityType) {
      switch (entityType) {
        case 'contact':
          return this.$t('tasks.entity_contact');
        case 'company':
          return this.$t('tasks.entity_company');
        case 'order':
          return this.$t('tasks.entity_order');
        case 'invoice':
          return this.$t('tasks.entity_invoice');
        default:
          return entityType;
      }
    },
    addToCalendar(task) {
      const icsData = [
        'BEGIN:VCALENDAR',
        'VERSION:2.0',
        'BEGIN:VEVENT',
        `UID:${task.id}@kompaktcrm.de`,
        `SUMMARY:${task.entity_title}`,
        `DESCRIPTION:${task.description}`,
        `DTSTART:${this.formatICSDate(task.deadline)}`,
        `DTEND:${this.formatICSDate(task.deadline)}`,
        'END:VEVENT',
        'END:VCALENDAR',
      ].join('\n');

      const blob = new Blob([icsData], { type: 'text/calendar' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${task.entity_title}.ics`;
      a.click();
      URL.revokeObjectURL(url);
    },
    formatICSDate(date) {
      const d = new Date(date);
      return d.toISOString().replace(/-|:|\.\d+/g, ''); // Format: YYYYMMDDTHHmmssZ
    },
  },
  mounted() {
    this.fetchTasks();
  },
};
</script>

<style scoped>
.task-container {
  margin-top: 20px;
}

.task-section {
  margin-bottom: 20px;
}

.task-card {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.task-content h5 {
  margin-bottom: 10px;
}

.task-content p {
  margin: 0;
}

.task-actions {
  margin-top: 10px;
}

.task-overdue {
  border-left: 5px solid red;
}

.task-soon {
  border-left: 5px solid orange;
}

.task-normal {
  border-left: 5px solid #007bff;
}

.task-card button {
  margin-right: 10px;
}
</style>
