import { createRouter, createWebHistory } from 'vue-router';
import AuthForm from '@/components/AuthForm.vue';
import Dashboard from '@/components/Dashboard.vue';
import ContactList from '@/components/ContactList.vue';
import ContactView from '@/components/ContactView.vue';
import ContactEdit from '@/components/ContactEdit.vue';
import ContactCreate from '@/components/ContactCreate.vue';
import CompanyList from '@/components/CompanyList.vue';
import CompanyCreate from '@/components/CompanyCreate.vue';
import CompanyEdit from '@/components/CompanyEdit.vue';
import CompanyView from '@/components/CompanyView.vue';
import OrderList from '@/components/OrderList.vue';
import OrderKanban from '@/components/OrderKanban.vue';
import OrderCreate from '@/components/OrderCreate.vue';
import OrderEdit from '@/components/OrderEdit.vue';
import OrderView from '@/components/OrderView.vue';
import InvoiceList from '@/components/InvoiceList.vue';
import InvoiceCreate from '@/components/InvoiceCreate.vue';
import InvoiceEdit from '@/components/InvoiceEdit.vue';
import InvoiceView from '@/components/InvoiceView.vue';
import Tasks from '@/views/Tasks.vue';
import Profile from '@/components/Profile.vue';
import Activation from '@/components/Activation.vue'; // Importiere die Aktivierungs-Komponente
//import Activation from '@/components/Timetracking.vue'; // Importiere die Aktivierungs-Komponente
import Timetracking from '@/components/Timetracking.vue';
import TimeFilter from '@/components/TimeFilter.vue';
import Upgrade from '@/components/Pricing.vue';

// Definiere die Routen
const routes = [
  {
    path: '/',
    name: 'Login',
    component: AuthForm,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = !!localStorage.getItem('authToken');
      if (isAuthenticated) {
        next({ name: 'Dashboard' });
      } else {
        next();
      }
    }
  },
  {
    path: '/activate/:code',
    name: 'Activation',
    component: Activation, // Die Aktivierungs-Komponente
    meta: { requiresAuth: false }, // Keine Authentifizierung erforderlich
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: '/contacts',
    name: 'ContactList',
    component: ContactList,
    meta: { requiresAuth: true },
  },
  {
    path: '/contacts/new',
    name: 'ContactCreate',
    component: ContactCreate,
    meta: { requiresAuth: true },
  },
  {
    path: '/contacts/:id',
    name: 'ContactView',
    component: ContactView,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/contacts/:id/edit',
    name: 'ContactEdit',
    component: ContactEdit,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/companies',
    name: 'CompanyList',
    component: CompanyList,
    meta: { requiresAuth: true },
  },
  {
    path: '/companies/new',
    name: 'CompanyCreate',
    component: CompanyCreate,
    meta: { requiresAuth: true },
  },
  {
    path: '/companies/:id',
    name: 'CompanyView',
    component: CompanyView,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/companies/:id/edit',
    name: 'CompanyEdit',
    component: CompanyEdit,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/orders',
    name: 'OrderList',
    component: OrderList,
    meta: { requiresAuth: true },
  },
  {
    path: '/orders/kanban',
    name: 'OrderKanban',
    component: OrderKanban,
    meta: { requiresAuth: true },
  },
  {
    path: '/orders/new',
    name: 'OrderCreate',
    component: OrderCreate,
    meta: { requiresAuth: true },
  },
  {
    path: '/orders/:id',
    name: 'OrderView',
    component: OrderView,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/orders/:id/edit',
    name: 'OrderEdit',
    component: OrderEdit,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/invoices',
    name: 'InvoiceList',
    component: InvoiceList,
    meta: { requiresAuth: true },
  },
  {
    path: '/invoices/new',
    name: 'InvoiceCreate',
    component: InvoiceCreate,
    meta: { requiresAuth: true },
  },
  {
    path: '/invoices/:id',
    name: 'InvoiceView',
    component: InvoiceView,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/invoices/:id/edit',
    name: 'InvoiceEdit',
    component: InvoiceEdit,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: Tasks,
    meta: { requiresAuth: true },
  },
  {
    path: '/profile',
    name: 'UserProfile',
    component: Profile, // Importiere die Profil-Seite
    meta: { requiresAuth: true }
  },
  {
    path: '/timetracking',
    name: 'TimeTracking',
    component: Timetracking,
    meta: { requiresAuth: true }
  },
  {
    path: '/timetracking/filter',
    name: 'TimeFilter',
    component: TimeFilter,
    meta: { requiresAuth: true }
  },
  {
    path: '/profile/upgrade',
    name: 'Upgrade',
    component: Upgrade,
    meta: { requiresAuth: true }
  },
];

// Erstelle den Router
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Füge einen globalen Navigationswächter hinzu, um die Authentifizierung zu überprüfen
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('authToken');
  
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Wenn die Route Authentifizierung erfordert und kein Token vorhanden ist, weiterleiten
    if (!token) {
      next({ name: 'Login' });
    } else {
      next(); // Authentifizierung ist vorhanden, weiter zur Route
    }
  } else {
    next(); // Route erfordert keine Authentifizierung, weiter zur Route
  }
});

export default router;
