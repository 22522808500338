<template>
  <div class="container mt-5">
    <h2 class="text-primary mb-4">{{ $t('order.details_title') }}</h2>
    <div v-if="order" class="card p-4 shadow-sm">
      <div class="row mb-3">
        <p><strong>{{ $t('order.order_number') }}:</strong> {{ order.order_number }}</p>
      </div>
      <div class="row mb-3">
        <p><strong>{{ $t('order.title') }}:</strong> {{ order.title }}</p>
      </div>
      <div class="row mb-3">
        <p><strong>{{ $t('order.description') }}:</strong> {{ order.description }}</p>
      </div>
      <div class="row mb-3">
        <p><strong>{{ $t('order.status') }}:</strong> {{ translateStatus(order.status) }}</p>
      </div>
      <div class="row mb-3">
        <p><strong>{{ $t('order.amount') }}:</strong> {{ order.amount }} {{ order.currency }}</p>
      </div>
      <div class="row mb-3">
        <p><strong>{{ $t('order.due_date') }}:</strong> {{ order.due_date }}</p>
      </div>
      <div class="row mb-3">
        <p><strong>{{ $t('order.company') }}:</strong> {{ order.company_name }}</p>
      </div>
      <div class="row mb-3">
        <p><strong>{{ $t('order.contact') }}:</strong> {{ order.contact_name }}</p>
      </div>
      <div class="mt-4">
        <button class="btn btn-warning me-2" @click="editOrder">{{ $t('common.edit') }}</button>
        <button class="btn btn-secondary" @click="goBack">{{ $t('common.back') }}</button>
      </div>
    </div>
    <div v-else>
      <p>{{ $t('order.loading') }}</p>
    </div>

    <!-- Hier wird die Timeline-Komponente integriert -->
    <Timeline v-if="order" entityType="order" :entityId="order.id" />
  </div>
</template>

<script>
import Timeline from '@/components/Timelines.vue'; // Importiere die Timeline-Komponente

export default {
  components: {
    Timeline
  },
  data() {
    return {
      order: null
    };
  },
  methods: {
    fetchOrder() {
      const token = localStorage.getItem('authToken');
      fetch(`https://kompaktcrm.de/beta/orders_api.php?id=${this.$route.params.id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => response.json())
      .then(data => {
        this.order = data;
      })
      .catch(error => {
        console.error(this.$t('order.error_fetching_order'), error);
      });
    },
    editOrder() {
      this.$router.push({ name: 'OrderEdit', params: { id: this.order.id } });
    },
    goBack() {
      this.$router.push({ name: 'OrderList' });
    }, translateStatus(status) {
      const statusMap = {
        unqualifiziert: 'unqualified',
        qualifiziert: 'qualified',
        inBearbeitung: 'in_progress',
        abgeschlossen: 'completed',
        zurückgestellt: 'on_hold',
        verloren: 'lost',
      };
      return this.$t(`order.status_${statusMap[status]}`);
    }
  },
  mounted() {
    this.fetchOrder();
  }
};
</script>

<style scoped>
.card {
  border-radius: 10px;
  background-color: #f8f9fa;
}

h2 {
  font-weight: bold;
}

p {
  margin: 0;
  font-size: 1rem;
}

.row + .row {
  margin-top: 10px;
}

button {
  width: 150px;
}
</style>
