<template>
    <div class="container">
        <div class="timetracking-container">
            <h3 class="timetracking-title">{{ $t('timetracking.overview') }}</h3>

            <!-- Filter-Optionen -->
            <div class="filter-section mb-4">
                <h5>{{ $t('timetracking.filter_options') }}</h5>
                <div class="row">
                    <div class="col-md-3 mb-3">
                        <label for="filterStartDate" class="form-label">{{ $t('timetracking.start_date') }}</label>
                        <input v-model="filterStartDate" type="date" id="filterStartDate" class="form-control">
                    </div>
                    <div class="col-md-3 mb-3">
                        <label for="filterEndDate" class="form-label">{{ $t('timetracking.end_date') }}</label>
                        <input v-model="filterEndDate" type="date" id="filterEndDate" class="form-control">
                    </div>
                    <div class="col-md-3 mb-3">
                        <label for="filterEntityType" class="form-label">{{ $t('timetracking.entity') }}</label>
                        <select v-model="filterEntityType" id="filterEntityType" class="form-select">
                            <option value="">{{ $t('common.all') }}</option>
                            <option value="Auftrag">{{ $t('entity.order') }}</option>
                            <option value="Unternehmen">{{ $t('entity.company') }}</option>
                            <option value="Kontakt">{{ $t('entity.contact') }}</option>
                        </select>
                    </div>
                    <div class="col-md-3 mb-3">
                        <label for="filterEntityId" class="form-label">{{ $t('timetracking.assignment_id') }}</label>
                        <select v-model="filterEntityId" id="filterEntityId" class="form-select">
                            <option v-if="filterEntityType === 'Auftrag'" v-for="order in orders" :value="order.id"
                                :key="order.id">{{ order.title }}</option>
                            <option v-if="filterEntityType === 'Unternehmen'" v-for="company in companies"
                                :value="company.id" :key="company.id">{{ company.name }}</option>
                            <option v-if="filterEntityType === 'Kontakt'" v-for="contact in contacts"
                                :value="contact.id" :key="contact.id">{{ contact.first_name }} {{ contact.last_name }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="d-flex justify-content-end">
                    <button @click="applyFilters" class="btn btn-primary">{{ $t('timetracking.apply_filters')
                        }}</button>
                    <button @click="resetFilters" class="btn btn-secondary ms-2">{{ $t('timetracking.reset_filters')
                        }}</button>
                </div>
            </div>

            <!-- Tabelle der Zeiteinträge -->
            <div class="tracked-entries mt-5">
                <h4>{{ $t('timetracking.saved_entries') }}</h4>
                <table class="table table-bordered table-hover">
                    <thead class="thead-light">
                        <tr>
                            <th scope="col">{{ $t('timetracking.description') }}</th>
                            <th scope="col">{{ $t('timetracking.assignment') }}</th>
                            <th scope="col">{{ $t('timetracking.start_time') }}</th>
                            <th scope="col">{{ $t('timetracking.end_time') }}</th>
                            <th scope="col">{{ $t('timetracking.pause_time') }}</th>
                            <th scope="col">{{ $t('timetracking.total_duration') }}</th>
                            <th scope="col">{{ $t('common.action') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="entry in savedEntries" :key="entry.id">
                            <td>{{ entry.description }}</td>
                            <td>{{ entry.entity_type }}: {{ entry.entity_name }}</td>
                            <td>{{ formatDateTime(entry.startTime) }}</td>
                            <td>{{ formatDateTime(entry.endTime) }}</td>
                            <td>{{ entry.pause_time || 0 }} {{ $t('timetracking.minutes') }}</td>
                            <td>{{ entry.duration_in_minutes }} {{ $t('timetracking.minutes') }}</td>
                            <td>
                                <button @click="deleteEntry(entry.id)" class="btn btn-danger btn-sm">{{
                                    $t('common.delete') }}</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- Export-Buttons -->
            <div class="export-buttons mt-4">
                <button @click="exportAsExcel" class="btn btn-success me-2">{{ $t('timetracking.export_excel')
                    }}</button>
                <button @click="exportAsPDF" class="btn btn-danger">{{ $t('timetracking.export_pdf') }}</button>
            </div>

            <!-- Anzeige der Gesamtdauer -->
            <div class="total-duration mt-4">
                <h5>{{ $t('timetracking.total_duration') }}: {{ totalDurationInMinutes }} {{ $t('timetracking.minutes')
                    }} ({{ totalDurationInHours }} {{ $t('timetracking.hours') }})</h5>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    data() {
        return {
            savedEntries: [],
            filterStartDate: "",
            filterEndDate: "",
            filterEntityType: "",
            filterEntityId: "",
            companies: [],
            contacts: [],
            orders: [],
            loadingCompanies: false,
            loadingContacts: false,
            loadingOrders: false,
        };
    },
    computed: {
        totalDurationInMinutes() {
            return this.savedEntries.reduce((total, entry) => total + parseFloat(entry.duration_in_minutes), 0);
        },
        totalDurationInHours() {
            return (this.totalDurationInMinutes / 60).toFixed(2);
        }
    },
    mounted() {
        this.fetchData();
        this.setDefaultDateRange();
        this.applyFilters();
    },
    methods: {
        async fetchData(retryCount = 0) {
            const token = localStorage.getItem('authToken');
            this.loadingCompanies = true;
            this.loadingContacts = true;
            this.loadingOrders = true;

            try {
                const [companiesRes, contactsRes, ordersRes] = await Promise.all([
                    fetch('https://kompaktcrm.de/beta/companies_api.php', {
                        headers: { Authorization: `Bearer ${token}` }
                    }),
                    fetch('https://kompaktcrm.de/beta/contacts_api.php', {
                        headers: { Authorization: `Bearer ${token}` }
                    }),
                    fetch('https://kompaktcrm.de/beta/orders_api.php', {
                        headers: { Authorization: `Bearer ${token}` }
                    })
                ]);

                const companies = companiesRes.ok ? await companiesRes.json() : null;
                const contacts = contactsRes.ok ? await contactsRes.json() : null;
                const orders = ordersRes.ok ? await ordersRes.json() : null;

                if (companies && contacts && orders) {
                    this.companies = companies;
                    this.contacts = contacts;
                    this.orders = orders;

                    this.loadingCompanies = false;
                    this.loadingContacts = false;
                    this.loadingOrders = false;
                } else {
                    throw new Error('Fehler beim Laden der Daten');
                }
            } catch (error) {
                console.error('Fehler beim Laden der Daten:', error);
                if (retryCount < 3) {
                    setTimeout(() => {
                        this.fetchData(retryCount + 1);
                    }, 5000);
                }
            }
        },
        setDefaultDateRange() {
            const now = new Date();
            const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
            const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

            this.filterStartDate = firstDay.toISOString().split("T")[0];
            this.filterEndDate = lastDay.toISOString().split("T")[0];
        },
        applyFilters() {
            const token = localStorage.getItem("authToken");

            // Passe die Start- und Enddaten an, um den gesamten Tag zu berücksichtigen
            const adjustedStartDate = new Date(this.filterStartDate);
            adjustedStartDate.setHours(0, 0, 0, 0);

            const adjustedEndDate = new Date(this.filterEndDate);
            adjustedEndDate.setHours(23, 59, 59, 999);

            // Formatierung in das richtige Format für PostgreSQL
            const startDateString = adjustedStartDate.toISOString().split(".")[0].replace("T", " ");
            const endDateString = adjustedEndDate.toISOString().split(".")[0].replace("T", " ");

            const payload = {
                action: 'filterEntries',
                start_date: startDateString,
                end_date: endDateString,
                entity_type: this.filterEntityType,
                entity_id: this.filterEntityId,
            };

            fetch(`https://kompaktcrm.de/beta/timetracking_api.php`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            })
                .then((response) => response.json())
                .then((data) => {
                    if (Array.isArray(data)) {
                        this.savedEntries = data.map((entry) => ({
                            ...entry,
                            startTime: new Date(entry.start_time),
                            endTime: new Date(entry.end_time),
                        }));
                    } else {
                        console.error("Unerwartetes Datenformat:", data);
                        this.savedEntries = [];
                    }
                })
                .catch((error) => console.error("Fehler beim Laden der Zeiteinträge:", error));
        },
        resetFilters() {
            const now = new Date();
            const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
            const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

            this.filterStartDate = firstDay.toISOString().split("T")[0];
            this.filterEndDate = lastDay.toISOString().split("T")[0];
            this.filterEntityType = "";
            this.filterEntityId = "";
            this.applyFilters();
        },
        formatDateTime(datetime) {
            return new Date(datetime).toLocaleString();
        },
        deleteEntry(entryId) {
            const token = localStorage.getItem("authToken");

            fetch(`https://kompaktcrm.de/beta/timetracking_api.php?id=${entryId}`, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.success) {
                        this.applyFilters(); // Liste der gefilterten Einträge neu laden
                    } else {
                        alert("Fehler: " + result.error);
                    }
                })
                .catch((error) =>
                    console.error("Fehler beim Löschen des Zeiteintrags:", error)
                );
        },
        exportAsExcel() {
            const token = localStorage.getItem("authToken");

            // Passe die Start- und Enddaten an, um den gesamten Tag zu berücksichtigen
            const adjustedStartDate = new Date(this.filterStartDate);
            adjustedStartDate.setHours(0, 0, 0, 0);

            const adjustedEndDate = new Date(this.filterEndDate);
            adjustedEndDate.setHours(23, 59, 59, 999);

            // Formatierung in das richtige Format für PostgreSQL
            const startDateString = adjustedStartDate.toISOString().split(".")[0].replace("T", " ");
            const endDateString = adjustedEndDate.toISOString().split(".")[0].replace("T", " ");

            const payload = {
                action: 'exportToExcel',
                start_date: startDateString,
                end_date: endDateString,
                entity_type: this.filterEntityType,
                entity_id: this.filterEntityId,
            };

            fetch(`https://kompaktcrm.de/beta/timetracking_api.php`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        const downloadLink = document.createElement('a');
                        downloadLink.href = `https://kompaktcrm.de/beta/xlsx/${data.file}`;
                        downloadLink.download = data.file;
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        document.body.removeChild(downloadLink);
                    } else {
                        console.error("Fehler beim Exportieren der Zeiteinträge:", data.error);
                    }
                })
                .catch(error => console.error("Fehler beim Exportieren der Zeiteinträge:", error));
        },
        exportAsPDF() {
            const token = localStorage.getItem("authToken");

            // Passe die Start- und Enddaten an, um den gesamten Tag zu berücksichtigen
            const adjustedStartDate = new Date(this.filterStartDate);
            adjustedStartDate.setHours(0, 0, 0, 0);

            const adjustedEndDate = new Date(this.filterEndDate);
            adjustedEndDate.setHours(23, 59, 59, 999);

            const startDateString = adjustedStartDate.toISOString().split(".")[0].replace("T", " ");
            const endDateString = adjustedEndDate.toISOString().split(".")[0].replace("T", " ");

            const payload = {
                action: 'exportToPDF',
                start_date: startDateString,
                end_date: endDateString,
                entity_type: this.filterEntityType,
                entity_id: this.filterEntityId,
            };

            fetch(`https://kompaktcrm.de/beta/timetracking_api.php`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        const link = document.createElement("a");
                        link.href = `https://kompaktcrm.de/beta/pdf/${data.file}`;
                        link.download = data.file;
                        link.click();
                    } else {
                        console.error("Fehler beim PDF-Export:", data.error);
                    }
                })
                .catch((error) => console.error("Fehler beim PDF-Export:", error));
        }
    }
};
</script>

<style scoped>
/* Timetracking container */
.timetracking-container {
    background-color: #f9f9f9;
    padding: 40px 20px;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    margin-bottom: 20px;
}

/* Title */
.timetracking-title {
    margin-bottom: 20px;
    font-weight: bold;
    color: #007bff;
}

/* Filter section */
.filter-section {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Tracked entries table */
.tracked-entries {
    margin-top: 40px;
}

.table {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.table thead {
    background-color: #007bff;
    color: #fff;
}

.table th,
.table td {
    padding: 15px;
    text-align: center;
    vertical-align: middle;
}

.table-hover tbody tr:hover {
    background-color: #f1f1f1;
}

/* Export buttons */
.export-buttons {
    margin-top: 20px;
}

/* Total duration display */
.total-duration {
    margin-top: 20px;
    font-weight: bold;
}
</style>