<template>
    <div class="form-wrapper">
      <div class="form-container">
        <div class="logo">
          <img src="@/assets/logo.png" alt="Logo" />
          <h1>kompaktCRM</h1>
        </div>
  
        <div v-if="message" :class="['message', messageType]">{{ message }}</div>
  
        <!-- Aktivierungsformular -->
        <div v-if="!isActivated">
          <h2>Account aktivieren</h2>
          <form @submit.prevent="activateAccount">
            <p>Wir aktivieren Ihr Konto mit dem Code: {{ activationCode }}</p>
            <button type="submit" class="btn btn-primary w-100">Konto aktivieren</button>
          </form>
        </div>
        
        <div v-else>
          <p class="success">Ihr Konto wurde erfolgreich aktiviert. Sie können sich jetzt <a @click="goToLogin">einloggen</a>.</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        activationCode: this.$route.params.code,
        message: '',
        messageType: '',
        isActivated: false,
      };
    },
    methods: {
      async activateAccount() {
        try {
          const response = await fetch('https://kompaktcrm.de/beta/activate.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ code: this.activationCode }),
          });
          const data = await response.json();
          this.message = data.message;
          this.messageType = data.success ? 'success' : 'error';
  
          if (data.success) {
            this.isActivated = true;
          }
        } catch (error) {
          console.error('Fehler:', error);
          this.message = 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.';
          this.messageType = 'error';
        }
      },
      goToLogin() {
        this.$router.push({ name: 'Login' });
      },
    },
  };
  </script>
  
  <style scoped>
  /* Verwende das gleiche Styling wie für die Login-Komponente */
  .form-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f8f9fa;
  }
  
  .form-container {
    max-width: 450px;
    width: 100%;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .form-container:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 40px rgba(0, 0, 0, 0.15);
  }
  
  .logo img {
    max-width: 120px;
  }
  
  .logo h1 {
    font-size: 28px;
    margin-top: 15px;
    font-weight: 600;
  }
  
  .message {
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .message.success {
    color: green;
  }
  
  .message.error {
    color: red;
  }
  </style>
  