<template>
  <div class="container mt-5">
    <h2 class="text-primary mb-4">{{ $t('company_create.title_view') }}</h2>
    <div v-if="company" class="card p-4 shadow-sm">
      <h3>{{ $t('company_view.basic_information') }}</h3>
      <div class="row mb-3">
        <div class="col-sm-6">
          <p><strong>{{ $t('company_create.name') }}:</strong> {{ company.name }}</p>
        </div>
        <div class="col-sm-6">
          <p><strong>{{ $t('company_create.registration_number') }}:</strong> {{ company.registration_number }}</p>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-6">
          <p><strong>{{ $t('company_create.vat_number') }}:</strong> {{ company.vat_number }}</p>
        </div>
        <div class="col-sm-6">
          <p><strong>{{ $t('company_create.industry') }}:</strong> {{ company.industry }}</p>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-6">
          <p><strong>{{ $t('company_create.founding_date') }}:</strong> {{ company.founding_date }}</p>
        </div>
        <div class="col-sm-6">
          <p><strong>{{ $t('company_create.company_size') }}:</strong> {{ company.company_size }}</p>
        </div>
      </div>

      <h3>{{ $t('company_create.contact_information') }}</h3>
      <div class="row mb-3">
        <div class="col-sm-6">
          <p><strong>{{ $t('company_create.address') }}:</strong> {{ company.address }}</p>
        </div>
        <div class="col-sm-6">
          <p><strong>{{ $t('company_create.address_additional') }}:</strong> {{ company.address_additional }}</p>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-6">
          <p><strong>{{ $t('company_create.city') }}:</strong> {{ company.city }}</p>
        </div>
        <div class="col-sm-6">
          <p><strong>{{ $t('company_create.state') }}:</strong> {{ company.state }}</p>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-6">
          <p><strong>{{ $t('company_create.country') }}:</strong> {{ company.country }}</p>
        </div>
        <div class="col-sm-6">
          <p><strong>{{ $t('company_create.phone') }}:</strong> {{ company.phone }}</p>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-6">
          <p><strong>{{ $t('company_create.email') }}:</strong> {{ company.email }}</p>
        </div>
        <div class="col-sm-6">
          <p><strong>{{ $t('company_create.website') }}:</strong> <a :href="company.website">{{ company.website }}</a></p>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-6">
          <p><strong>{{ $t('company_create.linkedin_page') }}:</strong> <a :href="company.linkedin_page">{{ company.linkedin_page }}</a></p>
        </div>
      </div>

      <h3>{{ $t('company_create.financial_information') }}</h3>
      <div class="row mb-3">
        <div class="col-sm-6">
          <p><strong>{{ $t('company_create.bank_details') }}:</strong> {{ company.bank_details }}</p>
        </div>
        <div class="col-sm-6">
          <p><strong>{{ $t('company_create.payment_terms') }}:</strong> {{ company.payment_terms }}</p>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-6">
          <p><strong>{{ $t('company_create.delivery_terms') }}:</strong> {{ company.delivery_terms }}</p>
        </div>
        <div class="col-sm-6">
          <p><strong>{{ $t('company_create.annual_revenue') }}:</strong> {{ company.annual_revenue }}</p>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-6">
          <p><strong>{{ $t('company_create.profit_margin') }}:</strong> {{ company.profit_margin }}%</p>
        </div>
        <div class="col-sm-6">
          <p><strong>{{ $t('company_create.project_budget') }}:</strong> {{ company.project_budget }}</p>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-6">
          <p><strong>{{ $t('company_create.funding_sources') }}:</strong> {{ company.funding_sources }}</p>
        </div>
        <div class="col-sm-6">
          <p><strong>{{ $t('company_create.credit_rating') }}:</strong> {{ company.credit_rating }}</p>
        </div>
      </div>

      <h3>{{ $t('company_create.company_information') }}</h3>
      <div class="row mb-3">
        <div class="col-sm-6">
          <p><strong>{{ $t('company_create.owners') }}:</strong> {{ company.owners }}</p>
        </div>
        <div class="col-sm-6">
          <p><strong>{{ $t('company_create.holdings') }}:</strong> {{ company.holdings }}</p>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-6">
          <p><strong>{{ $t('company_create.company_structure') }}:</strong> {{ company.company_structure }}</p>
        </div>
        <div class="col-sm-6">
          <p><strong>{{ $t('company_create.mission_statement') }}:</strong> {{ company.mission_statement }}</p>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-6">
          <p><strong>{{ $t('company_create.vision_statement') }}:</strong> {{ company.vision_statement }}</p>
        </div>
        <div class="col-sm-6">
          <p><strong>{{ $t('company_create.main_competitors') }}:</strong> {{ company.main_competitors }}</p>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-6">
          <p><strong>{{ $t('company_create.competitive_advantages') }}:</strong> {{ company.competitive_advantages }}</p>
        </div>
        <div class="col-sm-6">
          <p><strong>{{ $t('company_create.market_share') }}:</strong> {{ company.market_share }}</p>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-6">
          <p><strong>{{ $t('company_create.company_references') }}:</strong> {{ company.company_references }}</p>
        </div>
      </div>

      <div class="mt-4">
        <button class="btn btn-warning me-2" @click="editCompany">{{ $t('common.edit') }}</button>
        <button class="btn btn-secondary" @click="goBack">{{ $t('common.back') }}</button>
      </div>
    </div>
    <div v-else>
      <p>{{ $t('common.loading') }}</p>
    </div>
    <!-- Hier wird die Timeline-Komponente integriert -->
    <Timeline v-if="company" entityType="company" :entityId="company.id" />
  </div>
</template>

<script>
import Timeline from '@/components/Timelines.vue'; // Importiere die Timeline-Komponente

export default {
  components: {
    Timeline
  },
  data() {
    return {
      company: null,
    };
  },
  methods: {
    async fetchCompany() {
      const token = localStorage.getItem('authToken');
      try {
        const response = await fetch(`https://kompaktcrm.de/beta/companies_api.php?id=${this.$route.params.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (data.error) {
          console.error('Fehler beim Laden der Firma:', data.error);
        } else {
          this.company = data;
        }
      } catch (error) {
        console.error('Fehler beim Laden der Firma:', error);
      }
    },
    editCompany() {
      this.$router.push({ name: 'CompanyEdit', params: { id: this.company.id } });
    },
    goBack() {
      this.$router.push({ name: 'CompanyList' });
    },
  },
  mounted() {
    this.fetchCompany();
  },
};
</script>

<style scoped>
.card {
  border-radius: 10px;
  background-color: #f8f9fa;
}

h2, h3 {
  font-weight: bold;
}

p {
  margin: 0;
  padding: 5px 0;
}

.row + .row {
  margin-top: 10px;
}

button {
  width: 150px;
}
</style>
