<template>
  <div class="container mt-5">
    <h2 class="text-primary mb-4">{{ $t('invoice.invoices') }}</h2>
    <button class="btn btn-primary mb-4 custom-btn" @click="createInvoice">{{ $t('invoice.add_new_invoice') }}</button>
    <div v-if="invoices.length" class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>{{ $t('invoice.invoice_number') }}</th>
            <th>{{ $t('invoice.company') }}</th>
            <th>{{ $t('invoice.contact') }}</th>
            <th>{{ $t('invoice.amount') }}</th>
            <th>{{ $t('invoice.status') }}</th>
            <th>{{ $t('common.actions') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="invoice in invoices" :key="invoice.id">
            <td>{{ invoice.invoice_number }}</td>
            <td>{{ invoice.company_name }}</td>
            <td>{{ invoice.contact_name }}</td>
            <td>{{ invoice.total_amount }} {{ invoice.currency }}</td>
            <td>{{ translateStatus(invoice.status) }}</td>
            <td>
              <button class="btn btn-info btn-sm me-2" @click="viewInvoice(invoice.id)">{{ $t('common.view') }}</button>
              <button class="btn btn-warning btn-sm me-2" @click="editInvoice(invoice.id)">{{ $t('common.edit') }}</button>
              <button class="btn btn-danger btn-sm" @click="deleteInvoice(invoice.id)">{{ $t('common.delete') }}</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <p>{{ $t('invoice.no_invoices') }}</p>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      invoices: [],
    };
  },
  methods: {
    async fetchInvoices() {
      const token = localStorage.getItem('authToken');
      try {
        const response = await fetch('https://kompaktcrm.de/beta/invoices_api.php', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.invoices = await response.json();
      } catch (error) {
        console.error('Fehler beim Laden der Rechnungen:', error);
      }
    },
    createInvoice() {
      this.$router.push({ name: 'InvoiceCreate' });
    },
    editInvoice(id) {
      this.$router.push({ name: 'InvoiceEdit', params: { id } });
    },
    viewInvoice(id) {
      this.$router.push({ name: 'InvoiceView', params: { id } });
    },
    async deleteInvoice(id) {
      if (confirm('Sind Sie sicher, dass Sie diese Rechnung löschen möchten?')) {
        const token = localStorage.getItem('authToken');
        try {
          await fetch(`https://kompaktcrm.de/beta/invoices_api.php?id=${id}`, {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          this.fetchInvoices(); // Lade die Liste nach dem Löschen neu
        } catch (error) {
          console.error('Fehler beim Löschen der Rechnung:', error);
        }
      }
    },
    translateStatus(status) {
      const statusTranslations = {
        'Offen': this.$t('invoice.status_open'),
        'Beglichen': this.$t('invoice.status_paid'),
        'Überfällig': this.$t('invoice.status_overdue')
      };
      return statusTranslations[status] || status;
    }
  },
  mounted() {
    this.fetchInvoices();
  },
};
</script>

<style scoped>
h2 {
  font-weight: bold;
}

.table-responsive {
  width: 100%;
  overflow-x: auto;
}

@media (max-width: 768px) {
  .table-responsive {
    -webkit-overflow-scrolling: touch;
  }

  .btn.custom-btn {
    width: 100%;
  }
}
</style>
