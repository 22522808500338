<template>
  <div class="profile-container container mt-5">

    <!-- Nutzungslimits -->
    <div class="usage-limits-container mb-5">
      <h3 class="text-primary">{{ $t('profile.usage_limits.title') }}</h3>
      <div class="limit-item">
        <label>{{ $t('profile.usage_limits.contacts', { used: profile.contacts_count, limit: contactsLimit }) }}</label>
        <div class="progress">
          <div class="progress-bar bg-success" role="progressbar" :style="{ width: (profile.contacts_count / (contactsLimit) * 100).toFixed(2) + '%' }"></div>
        </div>
      </div>
      <div class="limit-item">
        <label>{{ $t('profile.usage_limits.companies', { used: profile.companies_count, limit: 10 }) }}</label>
        <div class="progress">
          <div class="progress-bar bg-info" role="progressbar" :style="{ width: (profile.companies_count / (companiesLimit) * 100).toFixed(2) + '%' }"></div>
        </div>
      </div>
      <div class="limit-item">
  <label>{{ $t('profile.usage_limits.storage', { used: (profile.total_storage_kb / (1024 * 1024)).toFixed(2), limit: 1 }) }}</label>
  <div class="progress">
    <div class="progress-bar bg-warning" role="progressbar" :style="{ width: (profile.total_storage_kb / (1024 * 1024 * storageLimit) * 100).toFixed(2) + '%' }"></div>
  </div>
</div>

      <div class="limit-item">
        <label>{{ $t('profile.usage_limits.invoices', { used: 2, limit: 2 }) }}</label>
        <div class="progress">
          <div class="progress-bar bg-danger" role="progressbar" :style="{ width: '100%' }"></div>
        </div>
      </div>
    </div>

    <h2 class="text-primary mb-4">{{ $t('profile.title') }}</h2>
    <form @submit.prevent="saveProfile">
      <!-- Persönliche Informationen -->
      <h4 class="text-secondary mb-3">{{ $t('profile.personal_information') }}</h4>
      <div class="mb-3">
        <label for="first_name" class="form-label">{{ $t('profile.first_name') }}</label>
        <input v-model="profile.first_name" type="text" class="form-control" id="first_name" required>
      </div>

      <div class="mb-3">
        <label for="last_name" class="form-label">{{ $t('profile.last_name') }}</label>
        <input v-model="profile.last_name" type="text" class="form-control" id="last_name" required>
      </div>

      <div class="mb-3">
        <label for="timezone" class="form-label">{{ $t('profile.timezone') }}</label>
        <select v-model="profile.timezone" class="form-select" id="timezone">
          <option v-for="zone in timezones" :key="zone" :value="zone">{{ zone }}</option>
        </select>
      </div>

      <div class="mb-3">
        <label for="preferred_language" class="form-label">{{ $t('profile.language') }}</label>
        <select v-model="profile.preferred_language" class="form-select" id="preferred_language">
          <option value="de">Deutsch</option>
          <option value="en">English</option>
          <option value="es">Español</option>
          <option value="fr">Français</option>
        </select>
      </div>

      <div class="mb-3">
        <label for="industry" class="form-label">{{ $t('profile.industry') }}</label>
        <input v-model="profile.industry" type="text" class="form-control" id="industry">
      </div>

      <hr>

      <!-- Rechnungsinformationen -->
      <h4 class="text-secondary mb-3">{{ $t('profile.billing_information') }}</h4>
      <div class="mb-3">
        <label for="billing_address" class="form-label">{{ $t('profile.billing_address') }}</label>
        <textarea v-model="profile.billing_address" class="form-control" id="billing_address"></textarea>
      </div>

      <div class="mb-3">
        <label for="billing_phone" class="form-label">{{ $t('profile.billing_phone') }}</label>
        <input v-model="profile.billing_phone" type="text" class="form-control" id="billing_phone">
      </div>

      <div class="mb-3">
        <label for="billing_email" class="form-label">{{ $t('profile.billing_email') }}</label>
        <input v-model="profile.billing_email" type="email" class="form-control" id="billing_email">
      </div>

      <hr>

      <!-- Logo hochladen -->
      <h4 class="text-secondary mb-3">{{ $t('profile.company_logo') }}</h4>
      <div class="mb-3">
        <label for="logo" class="form-label">{{ $t('profile.upload_logo') }}</label>
        <input @change="onFileChange" type="file" class="form-control" id="logo" accept=".jpg, .jpeg, .png">
        <img v-if="profile.logo" :src="`https://kompaktcrm.de/beta/${profile.logo}`" alt="Aktuelles Logo" class="mt-3" style="max-width: 200px;">
      </div>

      <hr>

      <!-- Passwort ändern -->
      <h4 class="text-primary mb-4">{{ $t('profile.change_password') }}</h4>
      <div class="mb-3">
        <label for="current_password" class="form-label">{{ $t('profile.current_password') }}</label>
        <input v-model="passwords.current_password" type="password" class="form-control" id="current_password">
      </div>

      <div class="mb-3">
        <label for="new_password" class="form-label">{{ $t('profile.new_password') }}</label>
        <input v-model="passwords.new_password" type="password" class="form-control" id="new_password">
      </div>

      <div class="mb-3">
        <label for="confirm_password" class="form-label">{{ $t('profile.confirm_password') }}</label>
        <input v-model="passwords.confirm_password" type="password" class="form-control" id="confirm_password">
      </div>

      <button type="submit" class="btn btn-primary">{{ $t('profile.save') }}</button>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      profile: {
        first_name: '',
        last_name: '',
        timezone: '',
        preferred_language: 'de',
        industry: '',
        billing_address: '',
        billing_phone: '',
        billing_email: '',
        contacts_count: 0,  // Beispielwert
        companies_count: 0,  // Beispielwert
        total_storage_kb: 0,  // Beispielwert
        invoices_count: 0  // Beispielwert
      },
      passwords: {
        current_password: '',
        new_password: '',
        confirm_password: ''
      },
      timezones: [
        "Africa/Abidjan", "Africa/Accra", "Africa/Addis_Ababa", "Africa/Algiers", "Africa/Asmara", "Africa/Bamako",
        "Africa/Bangui", "Africa/Banjul", "Africa/Bissau", "Africa/Blantyre", "Africa/Brazzaville", "Africa/Bujumbura",
        "Africa/Cairo", "Africa/Casablanca", "Africa/Ceuta", "Africa/Conakry", "Africa/Dakar", "Africa/Dar_es_Salaam",
        "Africa/Djibouti", "Africa/Douala", "Africa/El_Aaiun", "Africa/Freetown", "Africa/Gaborone", "Africa/Harare",
        "Africa/Johannesburg", "Africa/Juba", "Africa/Kampala", "Africa/Khartoum", "Africa/Kigali", "Africa/Kinshasa",
        "Africa/Lagos", "Africa/Libreville", "Africa/Lome", "Africa/Luanda", "Africa/Lubumbashi", "Africa/Lusaka",
        "Africa/Malabo", "Africa/Maputo", "Africa/Maseru", "Africa/Mbabane", "Africa/Mogadishu", "Africa/Monrovia",
        "Africa/Nairobi", "Africa/Ndjamena", "Africa/Niamey", "Africa/Nouakchott", "Africa/Ouagadougou", "Africa/Porto-Novo",
        "Africa/Sao_Tome", "Africa/Tripoli", "Africa/Tunis", "Africa/Windhoek", "America/Adak", "America/Anchorage",
        "America/Anguilla", "America/Antigua", "America/Araguaina", "America/Argentina/Buenos_Aires", "America/Argentina/Catamarca",
        "America/Argentina/Cordoba", "America/Argentina/Jujuy", "America/Argentina/La_Rioja", "America/Argentina/Mendoza",
        "America/Argentina/Rio_Gallegos", "America/Argentina/Salta", "America/Argentina/San_Juan", "America/Argentina/San_Luis",
        "America/Argentina/Tucuman", "America/Argentina/Ushuaia", "America/Aruba", "America/Asuncion", "America/Atikokan",
        "America/Bahia", "America/Bahia_Banderas", "America/Barbados", "America/Belem", "America/Belize", "America/Blanc-Sablon",
        "America/Boa_Vista", "America/Bogota", "America/Boise", "America/Cambridge_Bay", "America/Campo_Grande", "America/Cancun",
        "America/Caracas", "America/Cayenne", "America/Cayman", "America/Chicago", "America/Chihuahua", "America/Costa_Rica",
        "America/Creston", "America/Cuiaba", "America/Curacao", "America/Danmarkshavn", "America/Dawson", "America/Dawson_Creek",
        "America/Denver", "America/Detroit", "America/Dominica", "America/Edmonton", "America/Eirunepe", "America/El_Salvador",
        "America/Fortaleza", "America/Glace_Bay", "America/Goose_Bay", "America/Grand_Turk", "America/Grenada", "America/Guadeloupe",
        "America/Guatemala", "America/Guayaquil", "America/Guyana", "America/Halifax", "America/Havana", "America/Hermosillo",
        "America/Indiana/Indianapolis", "America/Indiana/Knox", "America/Indiana/Marengo", "America/Indiana/Petersburg",
        "America/Indiana/Tell_City", "America/Indiana/Vevay", "America/Indiana/Vincennes", "America/Indiana/Winamac",
        "America/Inuvik", "America/Iqaluit", "America/Jamaica", "America/Juneau", "America/Kentucky/Louisville",
        "America/Kentucky/Monticello", "America/Kralendijk", "America/La_Paz", "America/Lima", "America/Los_Angeles",
        "America/Lower_Princes", "America/Maceio", "America/Managua", "America/Manaus", "America/Marigot", "America/Martinique",
        "America/Matamoros", "America/Mazatlan", "America/Menominee", "America/Merida", "America/Metlakatla", "America/Mexico_City",
        "America/Miquelon", "America/Moncton", "America/Monterrey", "America/Montevideo", "America/Montserrat", "America/Nassau",
        "America/New_York", "America/Nipigon", "America/Nome", "America/Noronha", "America/North_Dakota/Beulah", "America/North_Dakota/Center",
        "America/North_Dakota/New_Salem", "America/Ojinaga", "America/Panama", "America/Pangnirtung", "America/Paramaribo",
        "America/Phoenix", "America/Port-au-Prince", "America/Port_of_Spain", "America/Porto_Velho", "America/Puerto_Rico",
        "America/Punta_Arenas", "America/Rainy_River", "America/Rankin_Inlet", "America/Recife", "America/Regina", "America/Resolute",
        "America/Rio_Branco", "America/Santarem", "America/Santiago", "America/Santo_Domingo", "America/Sao_Paulo", "America/Scoresbysund",
        "America/Sitka", "America/St_Barthelemy", "America/St_Johns", "America/St_Kitts", "America/St_Lucia", "America/St_Thomas",
        "America/St_Vincent", "America/Swift_Current", "America/Tegucigalpa", "America/Thule", "America/Thunder_Bay", "America/Tijuana",
        "America/Toronto", "America/Tortola", "America/Vancouver", "America/Whitehorse", "America/Winnipeg", "America/Yakutat",
        "America/Yellowknife", "Antarctica/Casey", "Antarctica/Davis", "Antarctica/DumontDUrville", "Antarctica/Macquarie",
        "Antarctica/Mawson", "Antarctica/Palmer", "Antarctica/Rothera", "Antarctica/Syowa", "Antarctica/Troll", "Antarctica/Vostok",
        "Arctic/Longyearbyen", "Asia/Aden", "Asia/Almaty", "Asia/Amman", "Asia/Anadyr", "Asia/Aqtau", "Asia/Aqtobe", "Asia/Ashgabat",
        "Asia/Atyrau", "Asia/Baghdad", "Asia/Bahrain", "Asia/Baku", "Asia/Bangkok", "Asia/Barnaul", "Asia/Beirut", "Asia/Bishkek",
        "Asia/Brunei", "Asia/Chita", "Asia/Choibalsan", "Asia/Colombo", "Asia/Damascus", "Asia/Dhaka", "Asia/Dili", "Asia/Dubai",
        "Asia/Dushanbe", "Asia/Famagusta", "Asia/Gaza", "Asia/Hebron", "Asia/Ho_Chi_Minh", "Asia/Hong_Kong", "Asia/Hovd", "Asia/Irkutsk",
        "Asia/Jakarta", "Asia/Jayapura", "Asia/Jerusalem", "Asia/Kabul", "Asia/Kamchatka", "Asia/Karachi", "Asia/Kathmandu", "Asia/Khandyga",
        "Asia/Kolkata", "Asia/Krasnoyarsk", "Asia/Kuala_Lumpur", "Asia/Kuching", "Asia/Kuwait", "Asia/Macau", "Asia/Magadan", "Asia/Makassar",
        "Asia/Manila", "Asia/Muscat", "Asia/Nicosia", "Asia/Novokuznetsk", "Asia/Novosibirsk", "Asia/Omsk", "Asia/Oral", "Asia/Phnom_Penh",
        "Asia/Pontianak", "Asia/Pyongyang", "Asia/Qatar", "Asia/Qostanay", "Asia/Qyzylorda", "Asia/Riyadh", "Asia/Sakhalin", "Asia/Samarkand",
        "Asia/Seoul", "Asia/Shanghai", "Asia/Singapore", "Asia/Srednekolymsk", "Asia/Taipei", "Asia/Tashkent", "Asia/Tbilisi", "Asia/Tehran",
        "Asia/Thimphu", "Asia/Tokyo", "Asia/Tomsk", "Asia/Ulaanbaatar", "Asia/Urumqi", "Asia/Ust-Nera", "Asia/Vientiane", "Asia/Vladivostok",
        "Asia/Yakutsk", "Asia/Yangon", "Asia/Yekaterinburg", "Asia/Yerevan", "Atlantic/Azores", "Atlantic/Bermuda", "Atlantic/Canary",
        "Atlantic/Cape_Verde", "Atlantic/Faroe", "Atlantic/Madeira", "Atlantic/Reykjavik", "Atlantic/South_Georgia", "Atlantic/St_Helena",
        "Atlantic/Stanley", "Australia/Adelaide", "Australia/Brisbane", "Australia/Broken_Hill", "Australia/Darwin", "Australia/Eucla",
        "Australia/Hobart", "Australia/Lindeman", "Australia/Lord_Howe", "Australia/Melbourne", "Australia/Perth", "Australia/Sydney",
        "Europe/Amsterdam", "Europe/Andorra", "Europe/Astrakhan", "Europe/Athens", "Europe/Belgrade", "Europe/Berlin", "Europe/Bratislava",
        "Europe/Brussels", "Europe/Bucharest", "Europe/Budapest", "Europe/Chisinau", "Europe/Copenhagen", "Europe/Dublin", "Europe/Gibraltar",
        "Europe/Guernsey", "Europe/Helsinki", "Europe/Isle_of_Man", "Europe/Istanbul", "Europe/Jersey", "Europe/Kaliningrad", "Europe/Kiev",
        "Europe/Kirov", "Europe/Lisbon", "Europe/Ljubljana", "Europe/London", "Europe/Luxembourg", "Europe/Madrid", "Europe/Malta",
        "Europe/Mariehamn", "Europe/Minsk", "Europe/Monaco", "Europe/Moscow", "Europe/Oslo", "Europe/Paris", "Europe/Podgorica",
        "Europe/Prague", "Europe/Riga", "Europe/Rome", "Europe/Samara", "Europe/San_Marino", "Europe/Sarajevo", "Europe/Saratov",
        "Europe/Simferopol", "Europe/Skopje", "Europe/Sofia", "Europe/Stockholm", "Europe/Tallinn", "Europe/Tirane", "Europe/Ulyanovsk",
        "Europe/Uzhgorod", "Europe/Vaduz", "Europe/Vatican", "Europe/Vienna", "Europe/Vilnius", "Europe/Volgograd", "Europe/Warsaw",
        "Europe/Zagreb", "Europe/Zaporozhye", "Europe/Zurich", "Indian/Antananarivo", "Indian/Chagos", "Indian/Christmas", "Indian/Cocos",
        "Indian/Comoro", "Indian/Kerguelen", "Indian/Mahe", "Indian/Maldives", "Indian/Mauritius", "Indian/Mayotte", "Indian/Reunion",
        "Pacific/Apia", "Pacific/Auckland", "Pacific/Bougainville", "Pacific/Chatham", "Pacific/Chuuk", "Pacific/Easter", "Pacific/Efate",
        "Pacific/Enderbury", "Pacific/Fakaofo", "Pacific/Fiji", "Pacific/Funafuti", "Pacific/Galapagos", "Pacific/Gambier", "Pacific/Guadalcanal",
        "Pacific/Guam", "Pacific/Honolulu", "Pacific/Kiritimati", "Pacific/Kosrae", "Pacific/Kwajalein", "Pacific/Majuro", "Pacific/Marquesas",
        "Pacific/Midway", "Pacific/Nauru", "Pacific/Niue", "Pacific/Norfolk", "Pacific/Noumea", "Pacific/Pago_Pago", "Pacific/Palau",
        "Pacific/Pitcairn", "Pacific/Pohnpei", "Pacific/Port_Moresby", "Pacific/Rarotonga", "Pacific/Saipan", "Pacific/Tahiti",
        "Pacific/Tarawa", "Pacific/Tongatapu", "Pacific/Wake", "Pacific/Wallis"
      ],
      selectedFile: null,
      contactsLimit: 25,  // Beispiel-Limit
      companiesLimit: 10,  // Beispiel-Limit
      storageLimit: 1,  // Beispiel-Limit in GB
      invoicesLimit: 2  // Beispiel-Limit
    };
  },
  methods: {
    async loadProfile() {
      const token = localStorage.getItem('authToken');
      try {
        const response = await fetch('https://kompaktcrm.de/beta/profile_api.php', {
          headers: { Authorization: `Bearer ${token}` }
        });
        this.profile = await response.json();
        this.setLanguage(this.profile.preferred_language);
      } catch (error) {
        console.error('Fehler beim Laden des Profils:', error);
      }
    },
    async saveProfile() {
      const token = localStorage.getItem('authToken');
      const formData = new FormData();

      // Füge alle Profildaten hinzu
      Object.keys(this.profile).forEach(key => {
        formData.append(key, this.profile[key]);
      });

      // Füge die Datei hinzu, wenn eine ausgewählt wurde
      if (this.selectedFile) {
        formData.append('logo', this.selectedFile);
      }

      try {
        const response = await fetch('https://kompaktcrm.de/beta/profile_api.php', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`
          },
          body: formData
        });

        const result = await response.json();
        if (result.success) {
          alert(this.$t('profile.success_message'));
          this.setLanguage(this.profile.preferred_language); // Setze die Sprache nach dem Speichern
          this.changePassword(); // Ändere das Passwort, falls es gesetzt wurde
        } else {
          alert(this.$t('profile.error_message'));
        }
      } catch (error) {
        console.error('Fehler beim Speichern des Profils:', error);
      }
    },
    setLanguage(lang) {
      this.$i18n.locale = lang; // Setze die Sprache in vue-i18n
      localStorage.setItem('preferred_language', lang); // Optional: Speichere die Sprache im localStorage
    },
    onFileChange(event) {
      this.selectedFile = event.target.files[0];
    },
    async changePassword() {
      if (!this.passwords.new_password) return;

      if (this.passwords.new_password !== this.passwords.confirm_password) {
        alert(this.$t('profile.password_mismatch'));
        return;
      }

      const token = localStorage.getItem('authToken');
      try {
        const response = await fetch('https://kompaktcrm.de/beta/change_password_api.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
            current_password: this.passwords.current_password,
            new_password: this.passwords.new_password
          })
        });
        const result = await response.json();
        if (result.success) {
          alert(this.$t('profile.password_success'));
          this.passwords.current_password = '';
          this.passwords.new_password = '';
          this.passwords.confirm_password = '';
        } else {
          alert(this.$t('profile.password_error'));
        }
      } catch (error) {
        console.error('Fehler beim Ändern des Passworts:', error);
      }
    }
  },
  mounted() {
    this.loadProfile();
  }
};
</script>

<style scoped>
.profile-container {
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2, h3, h4 {
  margin-bottom: 20px;
}

h4 {
  margin-top: 30px;
}

.form-control, .form-select {
  margin-bottom: 15px;
}

button[type="submit"] {
  margin-top: 20px;
}

.usage-limits-container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.limit-item {
  margin-bottom: 15px;
}

.progress {
  height: 20px;
}

.progress-bar {
  transition: width 0.4s ease;
}
</style>
