import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap CSS importieren
import './assets/main.scss'; // Benutzerdefinierte SCSS-Stile importieren
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './registerServiceWorker'
import './assets/custom.scss';
import { createI18n } from 'vue-i18n';
import de from './lang/de.json';
import en from './lang/en.json';
import es from './lang/es.json';
import fr from './lang/fr.json';

const i18n = createI18n({
  locale: 'de', // Standard-Sprache
  fallbackLocale: 'en', // Fallback-Sprache
  messages: {
    de,
    en,
    es,
    fr
  }
});

// Funktion zur Tokenprüfung
async function checkAuth() {
    const token = localStorage.getItem('authToken');
    if (token) {
      try {
        const response = await fetch('https://kompaktcrm.de/beta/validate_token.php', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (data.valid) {
          return true; // Token ist gültig
        } else {
          localStorage.removeItem('authToken');
          return false;
        }
      } catch (error) {
        console.error('Fehler beim Überprüfen des Tokens:', error);
        localStorage.removeItem('authToken');
        return false;
      }
    } else {
      return false;
    }
  }

// Anwendung starten, nachdem die Authentifizierung überprüft wurde
checkAuth().then(isAuthenticated => {
  if (!isAuthenticated) {
    router.push({ name: 'Login' });
  }
  
// Erstelle die App-Instanz
const app = createApp(App);

// Verwende i18n und den Router
app.use(i18n);
app.use(router);

// Montiere die App auf das DOM-Element mit der ID 'app'
app.mount('#app');
});
