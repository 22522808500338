<template>
  <div class="container mt-5">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <h2 class="text-primary">{{ $t('contacts.title') }}</h2>
      <div class="search-container">
        <div class="search-icon" @click="toggleSearch">
          <span v-if="!showSearch">🔍</span>
          <span v-if="showSearch">❌</span>
        </div>
        <div v-if="showSearch" class="search-form-wrapper">
          <input 
            v-model="searchQuery"
            type="text"
            class="form-control"
            :placeholder="$t('contacts.search_placeholder')"
            @input="filterContacts"
          />
        </div>
      </div>
    </div>

    <button class="btn btn-primary mb-4 custom-btn" @click="createContact">{{ $t('contacts.create_contact') }}</button>
    <div v-if="filteredContacts.length" class="list-group">
      <div
        class="list-group-item list-group-item-action flex-column align-items-start mb-3 shadow-sm"
        v-for="contact in filteredContacts"
        :key="contact.id"
      >
        <div class="d-flex flex-column flex-md-row w-100 justify-content-between align-items-start align-items-md-center">
          <div class="contact-info mb-2 mb-md-0">
            <h5 class="mb-1">
              {{ contact.salutation ? contact.salutation + ' ' : '' }}{{ contact.title ? contact.title + ' ' : '' }}{{ contact.first_name }} {{ contact.last_name }}
            </h5>
            <p class="mb-1 text-muted" v-if="contact.company_name || contact.job_title">
              <span v-if="contact.company_name">
                {{ $t('contacts.company') }}: {{ contact.company_name }}
              </span>
              <span v-if="contact.position" class="d-block d-md-inline ms-0 ms-md-3">
                {{ $t('contacts.position') }}: {{ contact.position }}
              </span>
              <span v-if="contact.job_title" class="d-block d-md-inline ms-0 ms-md-3">
                {{ $t('contacts.job_title') }}: {{ contact.job_title }}
              </span>
            </p>
            <p class="mb-1">
              <a :href="'mailto:' + contact.email" class="text-muted">{{ contact.email }}</a>
            </p>
            <p class="mb-1 text-muted">
              <span v-if="contact.phone">
                <a :href="'tel:' + contact.phone">{{ $t('contacts.phone') }}: {{ contact.phone }}</a>
              </span>
              <span v-if="contact.mobile" class="d-block d-md-inline ms-0 ms-md-3">
                <a :href="'tel:' + contact.mobile">{{ $t('contacts.mobile') }}: {{ contact.mobile }}</a>
              </span>
            </p>
          </div>
          <div class="contact-buttons d-flex flex-row align-items-center">
            <button class="btn btn-info btn-sm me-2" @click="viewContact(contact.id)">{{ $t('contacts.view') }}</button>
            <button class="btn btn-warning btn-sm me-2" @click="editContact(contact.id)">{{ $t('contacts.edit') }}</button>
            <button class="btn btn-danger btn-sm" @click="deleteContact(contact.id)">{{ $t('contacts.delete') }}</button>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <p class="text-muted">{{ $t('contacts.no_contacts_found') }}</p>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      contacts: [],
      searchQuery: '', // Query for searching contacts
      showSearch: false, // Controls the visibility of the search form
      filteredContacts: [] // List of filtered contacts
    };
  },
  methods: {
    async fetchContacts() {
      const token = localStorage.getItem('authToken');
      try {
        const response = await fetch('https://kompaktcrm.de/beta/contacts_api.php', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (data.error) {
          console.error('Fehler beim Laden der Kontakte:', data.error);
        } else {
          this.contacts = data;
          this.filteredContacts = data; // Initially, show all contacts
        }
      } catch (error) {
        console.error('Fehler beim Laden der Kontakte:', error);
      }
    },
    toggleSearch() {
      this.showSearch = !this.showSearch;
      if (!this.showSearch) {
        this.resetSearch();
      }
    },
    filterContacts() {
      const query = this.searchQuery.toLowerCase();
      this.filteredContacts = this.contacts.filter(contact => {
        return (
          contact.first_name.toLowerCase().includes(query) ||
          contact.last_name.toLowerCase().includes(query)
        );
      });
    },
    resetSearch() {
      this.searchQuery = '';
      this.filteredContacts = this.contacts;
    },
    createContact() {
      this.$router.push({ name: 'ContactCreate' });
    },
    viewContact(id) {
      this.$router.push({ name: 'ContactView', params: { id } });
    },
    editContact(id) {
      this.$router.push({ name: 'ContactEdit', params: { id } });
    },
    async deleteContact(id) {
      if (confirm('Sind Sie sicher, dass Sie diesen Kontakt löschen möchten?')) {
        const token = localStorage.getItem('authToken');
        try {
          const response = await fetch(`https://kompaktcrm.de/beta/contacts_api.php?id=${id}`, {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const data = await response.json();
          if (data.success) {
            this.fetchContacts(); // Liste nach dem Löschen aktualisieren
          } else {
            console.error('Fehler beim Löschen des Kontakts:', data.error);
          }
        } catch (error) {
          console.error('Fehler beim Löschen des Kontakts:', error);
        }
      }
    },
  },
  mounted() {
    this.fetchContacts();
  },
};
</script>

<style scoped>
h2 {
  font-weight: bold;
}

.list-group-item {
  background-color: #f8f9fa;
  border-radius: 10px;
}

p {
  margin: 0;
  font-size: 1rem;
}

/* Anpassung des Buttons "Neuen Kontakt erstellen" */
.custom-btn {
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: 600;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.custom-btn:hover {
  background-color: #004085;
  color: #fff;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Suchicon und Suchleiste */
.search-container {
  display: flex;
  align-items: center;
}

.search-icon {
  cursor: pointer;
  background-color: #e9ecef;
  border-radius: 50%;
  padding: 10px;
  text-align: center;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.search-icon:hover {
  background-color: #ced4da;
}

.search-form-wrapper {
  margin-left: 10px;
  width: 250px;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.search-form-wrapper input {
  border-radius: 20px;
  padding: 10px;
  border: 1px solid #ced4da;
  width: 100%;
  transition: border-color 0.3s ease;
}

.search-form-wrapper input:focus {
  border-color: #007bff;
  outline: none;
}

/* Kontaktinformationen */
.contact-info h5 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.contact-info p {
  margin-bottom: 0.25rem;
}

.contact-info a {
  color: #007bff;
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}

/* Responsiveness */
@media (max-width: 768px) {
  .search-form-wrapper {
    width: 200px;
  }

  .search-icon {
    width: 35px;
    height: 35px;
  }

  /* Stellen Sie sicher, dass die Schaltflächen unter den Kontaktinformationen angezeigt werden */
  .contact-buttons {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
  }

  .contact-buttons button {
    margin-bottom: 5px;
    width: 100%;
  }
}
</style>
