<template>
  <div class="container mt-5">
    <div v-if="invoice" class="invoice-details p-4 shadow-sm rounded bg-white">
      <h3 class="text-primary mb-4">{{ $t('invoice.invoice') }} {{ invoice.invoice_number }}</h3>
      <div class="invoice-meta mb-4">
        <p><strong>{{ $t('invoice.issue_date') }}:</strong> {{ invoice.issue_date }}</p>
        <p><strong>{{ $t('invoice.due_date') }}:</strong> {{ invoice.due_date }}</p>
        <p><strong>{{ $t('invoice.status') }}:</strong> <span :class="statusClass">{{ invoice.status | translateStatus }}</span></p>
        <p><strong>{{ $t('invoice.company') }}:</strong> {{ invoice.company_name }}</p>
        <p><strong>{{ $t('invoice.contact') }}:</strong> {{ invoice.contact_name }}</p>
        <p><strong>{{ $t('invoice.billing_address') }}:</strong> {{ invoice.billing_address }}</p>
        <p><strong>{{ $t('invoice.language') }}:</strong> {{ invoice.language }}</p>
        <p><strong>{{ $t('invoice.currency') }}:</strong> {{ invoice.currency }}</p>
        <p v-if="invoice.small_business"><strong>{{ $t('invoice.small_business') }}:</strong> {{ $t('invoice.small_business_notice') }}</p>
        <p><strong>{{ $t('invoice.payment_terms') }}:</strong> {{ invoice.payment_terms }}</p>
      </div>

      <h4 class="text-secondary mb-3">{{ $t('invoice.invoice_items') }}</h4>
      <table class="table table-striped">
        <thead class="table-light">
          <tr>
            <th>{{ $t('invoice.position') }}</th>
            <th>{{ $t('invoice.item_name') }}</th>
            <th>{{ $t('invoice.description') }}</th>
            <th>{{ $t('invoice.quantity') }}</th>
            <th>{{ $t('invoice.unit_price') }}</th>
            <th>{{ $t('invoice.total_price') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in invoice.items" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.description }}</td>
            <td>{{ item.quantity }}</td>
            <td>{{ item.unit_price }} {{ invoice.currency }}</td>
            <td>{{ (item.quantity * item.unit_price).toFixed(2) }} {{ invoice.currency }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="5" class="text-end"><strong>{{ $t('invoice.subtotal') }}:</strong></td>
            <td><strong>{{ discountedSubtotal }} {{ invoice.currency }}</strong></td>
          </tr>
          <tr v-if="invoice.discount_rate > 0">
            <td colspan="5" class="text-end"><strong>{{ $t('invoice.discount', { rate: invoice.discount_rate }) }}:</strong></td>
            <td><strong>{{ discount }} {{ invoice.currency }}</strong></td>
          </tr>
          <tr v-if="!invoice.small_business">
            <td colspan="5" class="text-end"><strong>{{ $t('invoice.tax', { rate: invoice.tax_rate }) }}:</strong></td>
            <td><strong>{{ tax }} {{ invoice.currency }}</strong></td>
          </tr>
          <tr>
            <td colspan="5" class="text-end"><strong>{{ $t('invoice.total') }}:</strong></td>
            <td><strong>{{ totalAmountFormatted }} {{ invoice.currency }}</strong></td>
          </tr>
        </tfoot>
      </table>

      <div class="d-flex justify-content-end mt-4">
        <button class="btn btn-outline-secondary me-2" @click="editInvoice">{{ $t('common.edit') }}</button>
        <button class="btn btn-primary" @click="downloadPdf" :disabled="isLoading">
          <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span v-if="isLoading">{{ $t('invoice.generating_pdf') }}</span>
          <span v-else>{{ $t('invoice.download_pdf') }}</span>
        </button>
      </div>

      <div v-if="errorMessage" class="alert alert-danger mt-3">
        {{ errorMessage }}
      </div>
    </div>
    <div v-else>
      <div class="alert alert-info">{{ $t('invoice.loading') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      invoice: null,
      isLoading: false,
      errorMessage: null,
    };
  },
  computed: {
    subtotal() {
      return this.invoice.items.reduce((sum, item) => sum + item.quantity * item.unit_price, 0).toFixed(2);
    },
    discount() {
      return ((this.subtotal * this.invoice.discount_rate) / 100).toFixed(2);
    },
    discountedSubtotal() {
      return (this.subtotal - this.discount).toFixed(2);
    },
    tax() {
      return this.invoice.small_business ? 0.00 : ((this.discountedSubtotal * this.invoice.tax_rate) / 100).toFixed(2);
    },
    totalAmountFormatted() {
      return (parseFloat(this.discountedSubtotal) + parseFloat(this.tax)).toFixed(2);
    },
    statusClass() {
      return {
        'text-success': this.invoice.status === 'bezahlt',
        'text-warning': this.invoice.status === 'offen',
        'text-danger': this.invoice.status === 'überfällig',
      };
    }
  },
  methods: {
    async fetchInvoice() {
      const token = localStorage.getItem('authToken');
      try {
        const response = await fetch(`https://kompaktcrm.de/beta/invoices_api.php?id=${this.$route.params.id}`, {
          headers: { 'Authorization': `Bearer ${token}` },
        });
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        this.invoice = await response.json();
      } catch (error) {
        console.error('Fehler beim Laden der Rechnung:', error);
      }
    },
    editInvoice() {
      this.$router.push({ name: 'InvoiceEdit', params: { id: this.invoice.id } });
    },
    async downloadPdf() {
      const token = localStorage.getItem('authToken');
      this.errorMessage = null;
      this.isLoading = true;

      try {
        const response = await fetch(`https://kompaktcrm.de/beta/invoices_api.php?action=generate_pdf`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ invoice_id: this.invoice.id }),
        });

        if (response.ok) {
          const blob = await response.blob();
          if (blob.type === "application/pdf") {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Rechnung_${this.invoice.invoice_number}.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            const text = await blob.text();
            throw new Error('Die Antwort ist keine PDF-Datei. Serverantwort: ' + text);
          }
        } else {
          const errorText = await response.text();
          throw new Error(`Fehler beim Generieren der PDF: ${response.statusText}. Serverantwort: ${errorText}`);
        }
      } catch (error) {
        console.error('Fehler beim Herunterladen der PDF:', error);
        this.errorMessage = this.$t('invoice.download_error') + ': ' + error.message;
      } finally {
        this.isLoading = false;
      }
    }
  },
  mounted() {
    this.fetchInvoice();
  }
};
</script>

<style scoped>
.invoice-details {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.invoice-meta p {
  margin: 0;
}

.table {
  margin-top: 20px;
  border-collapse: separate;
  border-spacing: 0;
}

.table th, .table td {
  padding: 12px;
  vertical-align: middle;
  border-top: 1px solid #dee2e6;
}

.table th {
  background-color: #f5f5f5;
  text-align: left;
}

.table tfoot tr td {
  background-color: #f5f5f5;
}

.text-end {
  text-align: right;
}

.status-badge {
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
}

.text-success {
  color: #28a745;
}

.text-warning {
  color: #ffc107;
}

.text-danger {
  color: #dc3545;
}

.btn {
  padding: 10px 20px;
  font-size: 14px;
}

.spinner-border {
  margin-right: 5px;
}
</style>
