<template>
  <div class="container mt-5">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <h2 class="text-primary">{{ $t('company_list.title') }}</h2>
      <div class="search-container">
        <div class="search-icon" @click="toggleSearch">
          <span v-if="!showSearch">🔍</span>
          <span v-if="showSearch">❌</span>
        </div>
        <div v-if="showSearch" class="search-form-wrapper">
          <input 
            v-model="searchQuery"
            type="text"
            class="form-control"
            :placeholder="$t('company_list.search_placeholder')"
            @input="filterCompanies"
          />
        </div>
      </div>
    </div>

    <button class="btn btn-primary mb-4 custom-btn" @click="createCompany">{{ $t('company_list.add_new_company') }}</button>
    <div v-if="filteredCompanies.length" class="list-group">
      <div
        class="list-group-item list-group-item-action flex-column align-items-start mb-3 shadow-sm"
        v-for="company in filteredCompanies"
        :key="company.id"
      >
        <div class="d-flex flex-column flex-md-row w-100 justify-content-between align-items-start align-items-md-center">
          <div class="company-info mb-2 mb-md-0">
            <h5 class="mb-1">{{ company.name }}</h5>
            <p class="mb-1"><strong>{{ $t('company_create.industry') }}:</strong> {{ company.industry }}</p>
            <p class="mb-1"><strong>{{ $t('company_create.address') }}:</strong> {{ company.postal_code }} {{ company.city }}, {{ company.country }}</p>
            <p class="mb-1"><strong>{{ $t('company_create.email') }}:</strong> <a :href="'mailto:' + company.email">{{ company.email }}</a></p>
            <p class="mb-1"><strong>{{ $t('company_create.phone') }}:</strong> <a :href="'tel:' + company.phone">{{ company.phone }}</a></p>
          </div>
          <div class="company-buttons d-flex flex-row align-items-center">
            <button class="btn btn-info btn-sm me-2" @click="viewCompany(company.id)">{{ $t('common.view') }}</button>
            <button class="btn btn-warning btn-sm me-2" @click="editCompany(company.id)">{{ $t('common.edit') }}</button>
            <button class="btn btn-danger btn-sm" @click="deleteCompany(company.id)">{{ $t('common.delete') }}</button>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <p class="text-muted">{{ $t('company_list.no_companies') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      companies: [],
      searchQuery: '', // Query for searching companies
      showSearch: false, // Controls the visibility of the search form
      filteredCompanies: [] // List of filtered companies
    };
  },
  methods: {
    async fetchCompanies() {
      const token = localStorage.getItem('authToken');
      try {
        const response = await fetch('https://kompaktcrm.de/beta/companies_api.php', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (data.error) {
          console.error('Fehler beim Laden der Firmen:', data.error);
        } else {
          this.companies = data;
          this.filteredCompanies = data; // Initially, show all companies
        }
      } catch (error) {
        console.error('Fehler beim Laden der Firmen:', error);
      }
    },
    toggleSearch() {
      this.showSearch = !this.showSearch;
      if (!this.showSearch) {
        this.resetSearch();
      }
    },
    filterCompanies() {
      const query = this.searchQuery.toLowerCase();
      this.filteredCompanies = this.companies.filter(company => {
        return company.name.toLowerCase().includes(query);
      });
    },
    resetSearch() {
      this.searchQuery = '';
      this.filteredCompanies = this.companies;
    },
    createCompany() {
      this.$router.push({ name: 'CompanyCreate' });
    },
    viewCompany(id) {
      this.$router.push({ name: 'CompanyView', params: { id } });
    },
    editCompany(id) {
      this.$router.push({ name: 'CompanyEdit', params: { id } });
    },
    async deleteCompany(id) {
      if (confirm(this.$t('company_list.confirm_delete'))) {
        const token = localStorage.getItem('authToken');
        try {
          const response = await fetch(`https://kompaktcrm.de/beta/companies_api.php?id=${id}`, {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const data = await response.json();
          if (data.success) {
            this.fetchCompanies(); // Liste nach dem Löschen aktualisieren
          } else {
            console.error('Fehler beim Löschen der Firma:', data.error);
          }
        } catch (error) {
          console.error('Fehler beim Löschen der Firma:', error);
        }
      }
    },
  },
  mounted() {
    this.fetchCompanies();
  },
};
</script>

<style scoped>
h2 {
  font-weight: bold;
}

.list-group-item {
  background-color: #f8f9fa;
  border-radius: 10px;
}

p {
  margin: 0;
  font-size: 1rem;
}

/* Anpassung des Buttons "Neue Firma hinzufügen" */
.custom-btn {
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: 600;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.custom-btn:hover {
  background-color: #004085;
  color: #fff;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Suchicon und Suchleiste */
.search-container {
  display: flex;
  align-items: center;
}

.search-icon {
  cursor: pointer;
  background-color: #e9ecef;
  border-radius: 50%;
  padding: 10px;
  text-align: center;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.search-icon:hover {
  background-color: #ced4da;
}

.search-form-wrapper {
  margin-left: 10px;
  width: 250px;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.search-form-wrapper input {
  border-radius: 20px;
  padding: 10px;
  border: 1px solid #ced4da;
  width: 100%;
  transition: border-color 0.3s ease;
}

.search-form-wrapper input:focus {
  border-color: #007bff;
  outline: none;
}

/* Unternehmensinformationen */
.company-info h5 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.company-info p {
  margin-bottom: 0.25rem;
}

.company-info a {
  color: #007bff;
  text-decoration: none;
}

.company-info a:hover {
  text-decoration: underline;
}

/* Responsiveness */
@media (max-width: 768px) {
  .search-form-wrapper {
    width: 200px;
  }

  .search-icon {
    width: 35px;
    height: 35px;
  }

  /* Stellen Sie sicher, dass die Schaltflächen unter den Unternehmensinformationen angezeigt werden */
  .company-buttons {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
  }

  .company-buttons button {
    margin-bottom: 5px;
    width: 100%;
  }
}
</style>
