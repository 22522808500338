<template>
    <div class="container mt-5">
      <div class="row">
        <div class="col-md-6 col-lg-4 card-container" v-for="card in cards" :key="card.title">
          <div class="card" :style="{ backgroundImage: `url(${card.image})` }" @click="loadPage(card.page)">
            <div class="overlay"></div>
            <h5>{{ $t(card.title) }}</h5>
            <p v-if="card.count !== null">{{ card.count }} {{ $t(card.title) }}</p>
            <p v-else>{{ card.error }}</p>
            <div v-if="card.extra">
              <p class="card-text">{{ $t('dashboard.invoices.unpaid_count') }}: {{ card.extra.unpaidCount }}</p>
              <p class="card-text">{{ $t('dashboard.invoices.unpaid_total') }}: {{ card.extra.unpaidTotal }}</p>
            </div>
            <div v-if="card.task">
              <p class="card-text">{{ $t('dashboard.tasks.unpaid_count') }}: {{ card.task.unpaidCount }}</p>
              <p class="card-text">{{ $t('dashboard.tasks.overdue_count') }}: {{ card.task.unpaidTotal }}</p>
            </div>
            <div v-if="card.title === 'dashboard.time_tracking.title' && isTracking" class="timer-display">
              <p>{{ $t('dashboard.time_tracking.current_tracking') }}: {{ formatTime(elapsedTime) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isTracking: false,
        startTime: null,
        elapsedTime: 0,
        timer: null,
        cards: [
        { title: 'dashboard.contacts', image: require('@/assets/contacts-visual.webp'), page: 'ContactList', count: null, error: null },
        { title: 'dashboard.companies', image: require('@/assets/companies-visual.webp'), page: 'CompanyList', count: null, error: null },
        { title: 'dashboard.orders', image: require('@/assets/orders-visual.webp'), page: 'OrderList', count: null, error: null },
        { title: 'dashboard.invoices.title', image: require('@/assets/invoices-visual.webp'), page: 'InvoiceList', count: null, error: null, extra: { unpaidCount: 0, unpaidTotal: '0.00' } },
        { title: 'dashboard.tasks.title', image: require('@/assets/tasks-visual.webp'), page: 'Tasks', count: null, error: null, task: { unpaidCount: 0, unpaidTotal: 0 } },
        { title: 'dashboard.time_tracking.title', image: require('@/assets/time-visual.webp'), page: 'TimeTracking', count: null, error: null, timer: null },
        { title: 'dashboard.feedback', image: require('@/assets/feedback-visual.webp'), page: 'Feedback', count: null, error: null }
      ],
      };
    },
    methods: {
      loadPage(page) {
        this.$router.push({ name: page });
      },
      async fetchData() {
        const token = localStorage.getItem('authToken');

        this.checkLocalTracking();
  
        try {
          let response = await fetch('https://kompaktcrm.de/beta/contacts_api.php?action=count', {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          let data = await response.json();
          this.cards[0].count = data.count || 0;
        } catch (error) {
          this.cards[0].error = 'Fehler beim Laden der Kontakte';
          console.error('Fehler:', error);
        }
  
        try {
          let response = await fetch('https://kompaktcrm.de/beta/companies_api.php?action=count', {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          let data = await response.json();
          this.cards[1].count = data.count || 0;
        } catch (error) {
          this.cards[1].error = 'Fehler beim Laden der Firmen';
          console.error('Fehler Companies Counter:', error);
        }
  
        try {
          let response = await fetch('https://kompaktcrm.de/beta/orders_api.php?action=count', {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          let data = await response.json();
          this.cards[2].count = data.count || 0;
        } catch (error) {
          this.cards[2].error = 'Fehler beim Laden der Aufträge';
          console.error('Fehler:', error);
        }
  
        try {
          let response = await fetch('https://kompaktcrm.de/beta/invoices_api.php?action=count', {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          let data = await response.json();
          this.cards[3].extra.unpaidCount = data.count || 0;
        } catch (error) {
          console.error('Fehler beim Laden der Anzahl offener Rechnungen:', error);
        }
  
        try {
          let response = await fetch('https://kompaktcrm.de/beta/invoices_api.php?action=total_unpaid', {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          let data = await response.json();
          this.cards[3].extra.unpaidTotal = data.total || '0.00';
        } catch (error) {
          console.error('Fehler beim Laden des offenen Rechnungsbetrages:', error);
        }
  
        try {
          let response = await fetch('https://kompaktcrm.de/beta/tasks_api.php?action=open', {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          let data = await response.json();
          
          this.cards[4].task.unpaidCount = data.opencount || 0;
          this.cards[4].task.unpaidTotal = data.overduecount || 0;
        } catch (error) {
          console.error('Fehler beim Laden der Aufgaben:', error);
        }

      },
      checkLocalTracking() {
      const savedStartTime = localStorage.getItem('startTime');

      if (savedStartTime) {
        this.startTime = new Date(savedStartTime);
        if (!isNaN(this.startTime.getTime())) {
          this.isTracking = true;
          this.elapsedTime = Date.now() - this.startTime.getTime();
          this.startTimer();
        }
      } else {
        this.isTracking = false;
      }
    },
    startTimer() {
      if (this.startTime) {
        this.timer = setInterval(() => {
          this.elapsedTime = Date.now() - this.startTime.getTime();
        }, 1000);
      }
    },
    formatTime(ms) {
      const totalSeconds = Math.floor(ms / 1000);
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    },
    },
    mounted() {
      this.fetchData();
    },
  beforeDestroy() {
    clearInterval(this.timer);
  }
  };
  </script>
  
  <style scoped>
  .card-container {
    margin-bottom: 20px;
  }
  
  .card {
    height: 200px;
    background-size: cover;
    background-position: center;
    color: white;
    padding: 20px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease;
    position: relative;
  }
  
  .card:hover {
    transform: translateY(-5px);
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* 50% Abdunklung */
    border-radius: 10px;
  }
  
  .card h5, .card p {
    position: relative;
    z-index: 1; /* Sicherstellen, dass Text über der Overlay bleibt */
    margin: 0;
    font-size: 1.25rem;
  }
  
  .card p {
    margin: 5px 0;
    font-size: 1rem;
  }



  </style>
  