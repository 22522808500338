<template>
  <div class="container mt-5">
    <h2 class="text-primary mb-4">{{ $t('contact_view.title') }}</h2>
    <div v-if="contact" class="card p-4 shadow-sm">
      <!-- Persönliche Informationen -->
      <h4 class="text-secondary mb-3">{{ $t('contact_create.personal_information') }}</h4>
      <div class="row mb-3">
        <div class="col-sm-4">
          <p><strong>{{ $t('contact_create.salutation') }}:</strong> {{ contact.salutation }}</p>
        </div>
        <div class="col-sm-4">
          <p><strong>{{ $t('contact_create.title_label') }}:</strong> {{ contact.title }}</p>
        </div>
        <div class="col-sm-4">
          <p><strong>{{ $t('contact_create.gender') }}:</strong> {{ translateGender(contact.gender) }}</p>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-6">
          <p><strong>{{ $t('contact_create.first_name') }}:</strong> {{ contact.first_name }}</p>
        </div>
        <div class="col-sm-6">
          <p><strong>{{ $t('contact_create.last_name') }}:</strong> {{ contact.last_name }}</p>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-6">
          <p><strong>{{ $t('contact_create.birthdate') }}:</strong> {{ formatDate(contact.birthdate) }}</p>
        </div>
        <div class="col-sm-6">
          <p><strong>{{ $t('contact_create.job_title') }}:</strong> {{ contact.job_title }}</p>
        </div>
      </div>

      <!-- Kontaktinformationen -->
      <h4 class="text-secondary mb-3">{{ $t('contact_create.contact_information') }}</h4>
      <div class="row mb-3">
        <div class="col-sm-6">
          <p><strong>{{ $t('contact_create.email') }}:</strong> {{ contact.email }}</p>
        </div>
        <div class="col-sm-6">
          <p><strong>{{ $t('contact_create.secondary_email') }}:</strong> {{ contact.secondary_email }}</p>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-6">
          <p><strong>{{ $t('contact_create.phone') }}:</strong> {{ contact.phone }}</p>
        </div>
        <div class="col-sm-6">
          <p><strong>{{ $t('contact_create.mobile') }}:</strong> {{ contact.mobile }}</p>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-12">
          <p><strong>{{ $t('contact_create.linkedin_profile') }}:</strong> <a :href="contact.linkedin_profile" target="_blank">{{ contact.linkedin_profile }}</a></p>
        </div>
      </div>

      <!-- Adressinformationen -->
      <h4 class="text-secondary mb-3">{{ $t('contact_create.address_information') }}</h4>
      <div class="row mb-3">
        <div class="col-sm-6">
          <p><strong>{{ $t('contact_create.street_address') }}:</strong> {{ contact.street_address }}</p>
        </div>
        <div class="col-sm-6">
          <p><strong>{{ $t('contact_create.address_addition') }}:</strong> {{ contact.address_addition }}</p>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-4">
          <p><strong>{{ $t('contact_create.postal_code') }}:</strong> {{ contact.postal_code }}</p>
        </div>
        <div class="col-sm-4">
          <p><strong>{{ $t('contact_create.city') }}:</strong> {{ contact.city }}</p>
        </div>
        <div class="col-sm-4">
          <p><strong>{{ $t('contact_create.state') }}:</strong> {{ contact.state }}</p>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-6">
          <p><strong>{{ $t('contact_create.country') }}:</strong> {{ contact.country }}</p>
        </div>
        <div class="col-sm-6">
          <p><strong>{{ $t('contact_create.customer_number') }}:</strong> {{ contact.customer_number }}</p>
        </div>
      </div>

      <!-- Firmeninformationen -->
      <h4 class="text-secondary mb-3" v-if="contact.company_name || contact.department || contact.position">{{ $t('contact_create.company_information') }}</h4>
      <div class="row mb-3" v-if="contact.company_name">
        <div class="col-sm-6">
          <p><strong>{{ $t('contact_create.company') }}:</strong> {{ contact.company_name }}</p>
        </div>
        <div class="col-sm-6" v-if="contact.department">
          <p><strong>{{ $t('contact_create.department') }}:</strong> {{ contact.department }}</p>
        </div>
      </div>
      <div class="row mb-3" v-if="contact.position">
        <div class="col-sm-6">
          <p><strong>{{ $t('contact_create.position') }}:</strong> {{ contact.position }}</p>
        </div>
      </div>

      <!-- Weitere Informationen -->
      <h4 class="text-secondary mb-3">{{ $t('contact_create.additional_information') }}</h4>
      <div class="row mb-3">
        <div class="col-sm-6">
          <p><strong>{{ $t('contact_create.languages') }}:</strong> {{ contact.languages }}</p>
        </div>
        <div class="col-sm-6">
          <p><strong>{{ $t('contact_create.reference_sources') }}:</strong> {{ contact.reference_sources }}</p>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-12">
          <p><strong>{{ $t('contact_create.expectations') }}:</strong></p>
          <p>{{ contact.expectations }}</p>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-12">
          <p><strong>{{ $t('contact_create.availability') }}:</strong> {{ contact.availability }}</p>
        </div>
      </div>

      <p><strong>{{ $t('contact_create.description') }}:</strong></p>
      <p>{{ contact.description }}</p>
      
      <div class="mt-4">
        <button class="btn btn-warning me-2" @click="editContact">{{ $t('contact_view.edit') }}</button>
        <button class="btn btn-secondary" @click="goBack">{{ $t('contact_view.back') }}</button>
      </div>
    </div>
    <div v-else>
      <p class="text-muted">{{ $t('contact_view.loading_contact') }}</p>
    </div>

    <!-- Hier wird die Timeline-Komponente integriert -->
    <Timeline v-if="contact" entityType="contact" :entityId="contact.id" />

  </div>
</template>


<script>
import Timeline from '@/components/Timelines.vue'; // Importiere die Timeline-Komponente

export default {
  components: {
    Timeline
  },
  data() {
    return {
      contact: null,
    };
  },
  methods: {
    async fetchContact() {
      try {
        const token = localStorage.getItem('authToken');
        const response = await fetch(`https://kompaktcrm.de/beta/contacts_api.php?id=${this.$route.params.id}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (data.error) {
          console.error('Fehler:', data.error);
        } else {
          this.contact = data;
        }
      } catch (error) {
        console.error('Fehler beim Laden des Kontakts:', error);
      }
    },
    editContact() {
      this.$router.push({ name: 'ContactEdit', params: { id: this.contact.id } });
    },
    goBack() {
      this.$router.push({ name: 'ContactList' });
    },
    formatDate(date) {
      if (!date) return null;
      return new Date(date).toLocaleDateString();
    },
    translateGender(gender) {
  switch (gender) {
    case 'm':
      return this.$t('contact_create.male');
    case 'w':
      return this.$t('contact_create.female');
    case 'd':
      return this.$t('contact_create.diverse');
    default:
      return this.$t('contact_view.no_gender');
  }
}
  },
  mounted() {
    this.fetchContact();
  },
};
</script>

<style scoped>
/* Stil für die Kontaktanzeige */
.card {
  border-radius: 10px;
  background-color: #f8f9fa;
}

h2, h4 {
  font-weight: bold;
}

p {
  margin: 0;
  font-size: 1rem;
}

.row + .row {
  margin-top: 10px;
}

button {
  width: 150px;
}
</style>
