<template>
  <div class="form-wrapper">
    <div class="plasma-background"></div>
    <div class="form-container">
      <div class="logo">
        <img src="@/assets/logo.png" alt="Logo" />
        <h1>kompaktCRM</h1>
      </div>

      <div v-if="message" :class="['message', messageType]">{{ message }}</div>

      <!-- Language Links for Login and Register -->
      <div class="language-links">
        <span v-for="(label, code) in languages" :key="code" @click="changeLanguage(code)" :class="{ active: currentLanguage === code }">
          {{ label }}
        </span>
      </div>

      <!-- Login Form -->
      <div v-if="activeForm === 'login'">
        <h2>{{ $t('login.title') }}</h2>
        <form @submit.prevent="submitLogin">
          <div class="mb-3">
            <label for="loginUsername" class="form-label">{{ $t('login.username') }}</label>
            <input v-model="login.username" type="text" class="form-control" id="loginUsername" required />
          </div>
          <div class="mb-3">
            <label for="loginPassword" class="form-label">{{ $t('login.password') }}</label>
            <input v-model="login.password" type="password" class="form-control" id="loginPassword" required />
          </div>
          <button type="submit" class="btn btn-primary w-100">{{ $t('login.button') }}</button>
        </form>
        <span class="toggle-link" @click="activeForm = 'register'">{{ $t('login.register_prompt') }}</span>
        <br />
        <span class="toggle-link" @click="activeForm = 'resetPassword'">{{ $t('login.forgot_password') }}</span>
      </div>

      <!-- Register Form -->
      <div v-if="activeForm === 'register'">
        <h2>{{ $t('register.title') }}</h2>
        <form @submit.prevent="submitRegister">
          <div class="mb-3">
            <label for="registerUsername" class="form-label">{{ $t('register.username') }}</label>
            <input v-model="register.username" type="text" class="form-control" id="registerUsername" required />
          </div>
          <div class="mb-3">
            <label for="registerEmail" class="form-label">{{ $t('register.email') }}</label>
            <input v-model="register.email" type="email" class="form-control" id="registerEmail" required />
          </div>
          <div class="mb-3">
            <label for="registerPassword" class="form-label">{{ $t('register.password') }}</label>
            <input v-model="register.password" type="password" class="form-control" id="registerPassword" required />
          </div>
          <div class="mb-3">
            <label for="registerLanguage" class="form-label">{{ $t('register.language') }}</label>
            <select v-model="currentLanguage" @change="changeLanguage(currentLanguage)" class="form-select" id="registerLanguage">
              <option v-for="(fullName, code) in fullLanguageNames" :key="code" :value="code">{{ fullName }}</option>
            </select>
          </div>
          <div class="mb-3 form-check small-text">
            <input v-model="register.agreeToTerms" type="checkbox" class="form-check-input" id="agreeToTerms" required />
            <label class="form-check-label" for="agreeToTerms">
              {{ $t('register.agree_terms') }}
            </label>
          </div>
          <button type="submit" class="btn btn-secondary w-100" :disabled="!register.agreeToTerms">{{ $t('register.button') }}</button>
        </form>
        <span class="toggle-link" @click="activeForm = 'login'">{{ $t('register.login_prompt') }}</span>
      </div>

      <!-- Password Reset Form -->
      <div v-if="activeForm === 'resetPassword'">
        <h2>{{ $t('reset_password.title') }}</h2>
        <form @submit.prevent="submitResetPassword">
          <div class="mb-3">
            <label for="resetEmail" class="form-label">{{ $t('reset_password.email') }}</label>
            <input v-model="reset.email" type="email" class="form-control" id="resetEmail" required />
          </div>
          <button type="submit" class="btn btn-primary w-100">{{ $t('reset_password.button') }}</button>
        </form>
        <span class="toggle-link" @click="activeForm = 'login'">{{ $t('reset_password.login_prompt') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeForm: 'login', // 'login', 'register', 'resetPassword'
      login: {
        username: '',
        password: '',
      },
      register: {
        username: '',
        email: '',
        password: '',
        agreeToTerms: false,
      },
      reset: {
        email: '',
      },
      message: '',
      messageType: '',
      languages: {
        en: 'EN',
        de: 'DE',
        es: 'ES',
        fr: 'FR'
      },
      fullLanguageNames: {
        en: 'English',
        de: 'Deutsch',
        es: 'Español',
        fr: 'Français'
      }
    };
  },
  computed: {
    currentLanguage() {
      return this.$i18n.locale;
    }
  },
  mounted() {
    this.detectLanguage();
  },
  methods: {
    async submitLogin() {
      try {
        const response = await fetch('https://kompaktcrm.de/beta/login-vue.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
            username: this.login.username,
            password: this.login.password,
          }),
        });
        const data = await response.json();
        this.message = data.message;
        this.messageType = data.success ? 'success' : 'error';

        if (data.success) {
          localStorage.setItem('authToken', data.token);
          localStorage.setItem('username', data.username);
          localStorage.setItem('email', data.email);
          this.$i18n.locale =  data.preferred_language;
          localStorage.setItem('preferred_language', data.preferred_language);
          localStorage.setItem('limits', data.limits);

          this.$root.isAuthenticated = true;
          this.$root.username = data.username;
          this.$router.push({ name: 'Dashboard' });
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },
    async submitRegister() {
      try {
        const response = await fetch('https://kompaktcrm.de/beta/register-vue.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
            username: this.register.username,
            email: this.register.email,
            password: this.register.password,
            language: this.currentLanguage,
          }),
        });
        const data = await response.json();
        this.message = data.message;
        this.messageType = data.success ? 'success' : 'error';

        if (data.success) {
          this.activeForm = 'login';
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },
    async submitResetPassword() {
      try {
        const response = await fetch('https://kompaktcrm.de/beta/reset-password-vue.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
            email: this.reset.email,
          }),
        });
        const data = await response.json();
        this.message = data.message;
        this.messageType = data.success ? 'success' : 'error';

        if (data.success) {
          this.activeForm = 'login';
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },
    detectLanguage() {
      const userLang = localStorage.getItem('preferred_language') || navigator.language || 'en';
      const langCode = userLang.split('-')[0]; // e.g., "en-US" -> "en"
      this.$i18n.locale = this.languages[langCode] ? langCode : 'en';
      localStorage.setItem('preferred_language', this.$i18n.locale);
    },
    changeLanguage(code) {
      this.$i18n.locale = code;
      localStorage.setItem('preferred_language', code);
    }
  },
};
</script>

<style scoped>
.form-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f9fa;
  background: rgba(248, 249, 250,.6);
  overflow: hidden;
}

.plasma-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at 25% 25%, #186E7B, transparent 20%),
              radial-gradient(circle at 75% 25%, #E72366, transparent 20%),
              radial-gradient(circle at 50% 75%, #F18D34, transparent 20%),
              rgb(248, 249, 250);
  background-size: cover;
  filter: blur(80px);
  z-index: -1;
}

@keyframes plasma {
  0%, 100% {
    transform: scale(1.5) translate(0, 0);
    background-position: 25% 25%, 75% 25%, 50% 75%;
  }
  25% {
    transform: scale(1.8) translate(50px, -250px);
    background-position: 35% 20%, 65% 30%, 60% 65%;
  }
  50% {
    transform: scale(1.6) translate(-150px, 150px);
    background-position: 15% 35%, 85% 20%, 40% 85%;
  }
  75% {
    transform: scale(2) translate(-50px, 150px);
    background-position: 25% 45%, 75% 15%, 50% 80%;
  }
}

.form-container {
  max-width: 450px;
  width: 100%;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  z-index: 1;
}

.form-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.15);
}

.logo img {
  max-width: 120px;
}

.logo h1 {
  font-size: 28px;
  margin-top: 15px;
  font-weight: 600;
}

.message {
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
}

.message.success {
  color: green;
}

.message.error {
  color: red;
}

.toggle-link {
  cursor: pointer;
  color: #007bff;
  text-decoration: underline;
  display: inline-block;
  margin-top: 10px;
}

.language-links {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.language-links span {
  margin-left: 10px;
  cursor: pointer;
  color: #007bff;
  text-decoration: underline;
}

.language-links span.active {
  font-weight: bold;
  color: #0056b3;
}

.language-links span:hover {
  color: #0056b3;
}

.form-select {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.small-text {
  font-size: 0.8rem;
}
</style>
