<template>
  <div class="container mt-5">
    <div class="d-flex flex-column flex-md-row justify-content-between mb-3">
      <h2 class="text-primary mb-4">{{ $t('order.list_title') }}</h2>
      <div class="d-flex flex-column flex-md-row">
        <button class="btn btn-secondary mb-2 me-md-2" @click="switchToKanban">{{ $t('order.switch_to_kanban') }}</button>
        <button class="btn btn-primary mb-2" @click="createOrder">{{ $t('order.create_new') }}</button>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>{{ $t('order.order_number') }}</th>
            <th>{{ $t('order.title') }}</th>
            <th>{{ $t('order.status') }}</th>
            <th>{{ $t('order.amount') }}</th>
            <th>{{ $t('order.due_date') }}</th>
            <th>{{ $t('common.actions') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="order in orders" :key="order.id">
            <td>{{ order.order_number }}</td>
            <td>{{ order.title }}</td>
            <td>{{ translateStatus(order.status) }}</td>
            <td>{{ order.amount }} {{ order.currency }}</td>
            <td>{{ order.due_date }}</td>
            <td>
              <button class="btn btn-info btn-sm me-2" @click="viewOrder(order.id)">{{ $t('common.view') }}</button>
              <button class="btn btn-warning btn-sm me-2" @click="editOrder(order.id)">{{ $t('common.edit') }}</button>
              <button class="btn btn-danger btn-sm" @click="deleteOrder(order.id)">{{ $t('common.delete') }}</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      orders: [],
    };
  },
  methods: {
    async fetchOrders() {
      const token = localStorage.getItem('authToken');
      try {
        const response = await fetch('https://kompaktcrm.de/beta/orders_api.php', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (data.error) {
          console.error(this.$t('order.error_loading_orders'), data.error);
        } else {
          this.orders = data;
        }
      } catch (error) {
        console.error(this.$t('order.error_loading_orders'), error);
      }
    },
    switchToKanban() {
      localStorage.setItem('preferredView', 'kanban');
      this.$router.push({ name: 'OrderKanban' });
    },
    createOrder() {
      this.$router.push({ name: 'OrderCreate' });
    },
    viewOrder(id) {
      this.$router.push({ name: 'OrderView', params: { id } });
    },
    editOrder(id) {
      this.$router.push({ name: 'OrderEdit', params: { id } });
    },
    translateStatus(status) {
      const statusMap = {
        unqualifiziert: 'unqualified',
        qualifiziert: 'qualified',
        inBearbeitung: 'in_progress',
        abgeschlossen: 'completed',
        zurückgestellt: 'on_hold',
        verloren: 'lost',
      };
      return this.$t(`order.status_${statusMap[status]}`);
    },
    async deleteOrder(id) {
      const confirmDelete = confirm(this.$t('order.confirm_delete'));
      if (confirmDelete) {
        const token = localStorage.getItem('authToken');
        try {
          const response = await fetch(`https://kompaktcrm.de/beta/orders_api.php?id=${id}`, {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const result = await response.json();
          if (result.success) {
            this.fetchOrders();
          } else {
            console.error(this.$t('order.error_deleting_order'), result.error);
          }
        } catch (error) {
          console.error(this.$t('order.error_deleting_order'), error);
        }
      }
    },
  },
  mounted() {
    const preferredView = localStorage.getItem('preferredView');
    if (preferredView === 'kanban') {
      this.$router.push({ name: 'OrderKanban' });
    } else {
      this.fetchOrders();
    }
  },
};
</script>

<style scoped>
h2 {
  font-weight: bold;
}

.table-responsive {
  width: 100%;
  overflow-x: auto;
}

@media (max-width: 768px) {
  .table-responsive {
    -webkit-overflow-scrolling: touch;
  }

  .d-flex.flex-md-row {
    flex-direction: column;
  }
  
  .btn {
    width: 100%;
    margin-bottom: 10px;
  }
}
</style>
