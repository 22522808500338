<template>
  <div id="app" class="d-flex flex-column min-vh-100">
    <nav v-if="isAuthenticated" class="navbar navbar-expand-lg navbar-light navbar-light-mode">
      <div class="container-fluid">
        <a class="navbar-brand d-flex align-items-center" @click="loadPage('Dashboard')">
          <img :src="require('@/assets/logo.png')" alt="Logo" class="logo"> 
          <span>kompaktCRM</span>
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav me-auto">
            <li class="nav-item">
              <a class="nav-link" :class="{ active: isActive(['ContactList']) }" @click="loadPage('ContactList')">
                {{ $t('navbar.contacts') }}
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" :class="{ active: isActive(['CompanyList']) }" @click="loadPage('CompanyList')">
                {{ $t('navbar.companies') }}
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" :class="{ active: isActive(['OrderList']) }" @click="loadPage('OrderList')">
                {{ $t('navbar.orders') }}
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" :class="{ active: isActive(['InvoiceList']) }" @click="loadPage('InvoiceList')">
                {{ $t('navbar.invoices') }}
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" :class="{ active: isActive(['Tasks']) }" @click="loadPage('Tasks')">
                {{ $t('navbar.tasks') }}
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" :class="{ active: isActive(['TimeTracking']) }" @click="loadPage('TimeTracking')">
                {{ $t('navbar.times') }}
              </a>
            </li>
          </ul>
          <div class="user-profile dropdown">
            <img :src="require('@/assets/user-icon.png')" alt="User Icon" class="user-icon rounded-circle" @click="toggleDropdown">
            <span class="user-profile-name dropdown-toggle" @click="toggleDropdown">{{ username }}</span>
            <div v-if="dropdownVisible" class="dropdown-menu dropdown-menu-end show">
              <a class="dropdown-item" href="#" @click="openProfile">{{ $t('navbar.profile') }}</a>
              <a class="dropdown-item text-danger" href="#" @click="logout">{{ $t('navbar.logout') }}</a>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <div class="main-content flex-fill">
      <router-view></router-view>
    </div>

    <footer v-if="isAuthenticated" class="footer mt-auto py-3 bg-light">
      <div class="container text-center">
        <p>&copy; 2024 kompaktCRM. {{ $t('footer.copyright') }}</p>
        <a href="https://hanseat.me/impressum" target="_blank" class="text-reset">{{ $t('footer.imprint') }}</a> | 
        <a href="https://hanseat.me/datenschutz-crm" target="_blank" class="text-reset">{{ $t('footer.privacy') }}</a> |
        <a href="https://hanseat.me" target="_blank" class="text-reset">hanseatische medienfabrik</a>
      </div>
    </footer>
  </div>
</template>



<script>
export default {
  data() {
    return {
      isAuthenticated: false, // Status zur Authentifizierung
      username: '', // Benutzername für die Anzeige
      dropdownVisible: false, // Steuerung des Dropdowns
    };
  },
  methods: {
    async checkAuth() {
      const token = localStorage.getItem('authToken');
      const storedUsername = localStorage.getItem('username');
      
      if (token) {
        try {
          const response = await fetch('https://kompaktcrm.de/beta/validate_token.php', {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          const data = await response.json();
          if (data.valid) {
            this.isAuthenticated = true;
            this.username = storedUsername ? storedUsername : 'Benutzer';
          } else {
            this.handleLogout();
          }
        } catch (error) {
          console.error('Fehler beim Überprüfen des Tokens:', error);
          this.handleLogout();
        }
      } else {
        this.$router.push({ name: 'Login' });
      }
    },
    loadPage(page) {
      this.$router.push({ name: page });
    },
    logout() {
      this.handleLogout();
    },
    openProfile() {
      this.$router.push({ name: 'UserProfile' });
    },
    handleLogout() {
      localStorage.removeItem('authToken');
      localStorage.removeItem('username');
      this.isAuthenticated = false;

      if (this.$route.name !== 'Activation') {
      this.$router.push({ name: 'Login' });
      }
    },
    isActive(routeNames) {
      return routeNames.includes(this.$route.name);
    },
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
    },
    closeDropdown() {
      this.dropdownVisible = false;
    },
    setLanguage(lang) {
      this.$i18n.locale = lang; // Setze die Sprache in vue-i18n
      localStorage.setItem('preferred_language', lang); // Optional: Speichere die Sprache im localStorage
    },
  },
  mounted() {
    document.title = "kompaktCRM - CRM Made Easy";
    this.checkAuth();
    const savedLanguage = localStorage.getItem('preferred_language');
  if (savedLanguage) {
    this.setLanguage(savedLanguage);
  }
  },
  watch: {
    // Schließe das Dropdown, wenn der Benutzer auf eine andere Route wechselt
    $route() {
      this.closeDropdown();
    },
  },
};
</script>


<style scoped>
/* Globale Stile */
.navbar-light-mode {
  background-color: #f8f9fa;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar-brand {
  display: flex;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
}

.navbar-brand .logo {
  height: 30px;
  margin-right: 10px;
}

.nav-item .nav-link {
  font-size: 16px;
  font-weight: 500;
  color: #343a40;
  cursor: pointer;
}

.nav-item .nav-link.active {
  color: #007bff;
  font-weight: bold;
}

.nav-item .nav-link:hover {
  color: #0056b3;
}

.user-profile {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.user-icon {
  height: 30px;
  margin-right: 10px;
  border-radius: 50%;
}

.user-profile-name {
  font-weight: 500;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 10px;
  padding: 10px 0;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  min-width: 150px;
  z-index: 1000;
}

.dropdown-item {
  padding: 8px 20px;
  color: #333;
  text-decoration: none;
  display: block;
}

.dropdown-item:hover {
  background-color: #f8f9fa;
}

.dropdown-item.text-danger:hover {
  background-color: #f8d7da;
}

/* Sicherstellen, dass der Footer am unteren Rand bleibt */
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.flex-fill {
  flex: 1;
}

/* Footer Stile */
.footer {
  background-color: #f8f9fa;
  padding: 1rem 0;
  font-size: 0.9rem;
  color: #6c757d;
}

.footer a {
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
}

.footer a:hover {
  text-decoration: underline;
}

/* Mobile Ansichten */
@media (max-width: 768px) {
  .navbar-nav {
    text-align: center;
    margin-top: 10px;
  }

  .user-profile {
    justify-content: center;
    margin-top: 10px;
  }

  .navbar-collapse {
    text-align: center;
  }
}


</style>

