<template>
  <div class="container mt-5">
    <div class="d-flex flex-column align-items-start mb-3">
      <h2 class="text-primary mb-4">{{ $t('kanban.view') }}</h2>
      <div class="d-flex flex-column flex-md-row w-100 justify-content-start">
        <button class="btn btn-secondary mb-2 me-md-2" @click="switchToList">{{ $t('kanban.switch_to_list') }}</button>
        <button class="btn btn-primary mb-2" @click="createOrder">{{ $t('kanban.create_order') }}</button>
      </div>
    </div>
    
    <div v-if="isMobileView" class="alert alert-info">
      <p>{{ $t('kanban.mobile_view_alert') }}</p>
      <button class="btn btn-link p-0" @click="switchToList">{{ $t('kanban.switch_to_list') }}</button>
    </div>
    
    <div v-else class="kanban-board">
      <div
        class="kanban-column"
        v-for="(column, status) in kanbanColumns"
        :key="status"
        :data-status="status"
        @dragover.prevent
        @drop="onDrop($event, status)"
      >
        <h5>{{ column.title }}</h5>
        <div class="kanban-cards">
          <div
            class="kanban-card"
            v-for="order in column.orders"
            :key="order.id"
            :data-id="order.id"
            draggable="true"
            @dragstart="onDragStart($event, order)"
          >
            <h6 @click="viewOrder(order.id)" class="clickable-title">{{ order.title }}</h6>
            <p v-if="order.company_name"><small>{{ $t('kanban.customer') }}: {{ order.company_name }}</small></p>
            <p v-if="order.contact_name"><small>{{ $t('kanban.contact') }}: {{ order.contact_name }}</small></p>
            <p><small>{{ $t('kanban.amount') }}: {{ order.amount }} {{ order.currency }}</small></p>
            <p><small>{{ $t('kanban.due_date') }}: {{ order.due_date }}</small></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      orders: [],
      kanbanColumns: {
        unqualifiziert: { title: this.$t('kanban.unqualified'), orders: [] },
        qualifiziert: { title: this.$t('kanban.qualified'), orders: [] },
        inBearbeitung: { title: this.$t('kanban.in_progress'), orders: [] },
        abgeschlossen: { title: this.$t('kanban.completed'), orders: [] },
        zurückgestellt: { title: this.$t('kanban.on_hold'), orders: [] },
        verloren: { title: this.$t('kanban.lost'), orders: [] },
      },
      isMobileView: false,
    };
  },
  methods: {
    checkView() {
      this.isMobileView = window.innerWidth <= 768;
    },
    async fetchOrders() {
      const token = localStorage.getItem('authToken');
      try {
        const response = await fetch('https://kompaktcrm.de/beta/orders_api.php', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (data.error) {
          console.error(this.$t('kanban.load_error'), data.error);
        } else {
          this.orders = data;
          this.populateKanban();
        }
      } catch (error) {
        console.error(this.$t('kanban.load_error'), error);
      }
    },
    populateKanban() {
      for (const status in this.kanbanColumns) {
        this.kanbanColumns[status].orders = this.orders.filter((order) => order.status === status);
      }
    },
    switchToList() {
      localStorage.setItem('preferredView', 'list');
      this.$router.push({ name: 'OrderList' });
    },
    createOrder() {
      this.$router.push({ name: 'OrderCreate' });
    },
    viewOrder(id) {
      this.$router.push({ name: 'OrderView', params: { id } });
    },
    onDragStart(event, order) {
      event.dataTransfer.setData('orderId', order.id);
    },
    async onDrop(event, newStatus) {
      const orderId = event.dataTransfer.getData('orderId');
      const token = localStorage.getItem('authToken');

      try {
        const response = await fetch('https://kompaktcrm.de/beta/orders_api.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ id: orderId, status: newStatus }),
        });
        const result = await response.json();
        if (result.success) {
          console.log(this.$t('kanban.status_update_success'));
          this.fetchOrders();
        } else {
          console.error(this.$t('kanban.status_update_error'), result.error);
        }
      } catch (error) {
        console.error(this.$t('kanban.status_update_error'), error);
      }
    },
  },
  mounted() {
    this.checkView();
    window.addEventListener('resize', this.checkView);

    const preferredView = localStorage.getItem('preferredView');
    if (preferredView !== 'kanban') {
      this.$router.push({ name: 'OrderList' });
    } else {
      this.fetchOrders();
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkView);
  },
};
</script>

<style scoped>
.kanban-board {
  display: flex;
  gap: 10px;
}
.kanban-column {
  width: 18%;
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 5px;
}
.kanban-cards {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 50px;
}
.kanban-card {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: grab;
}
.clickable-title {
  cursor: pointer;
  color: #007bff;
  text-decoration: underline;
}
</style>
