<template>
  <div class="timeline-container">
    <h3 class="timeline-title">{{ $t('timeline.title') }}</h3>

    <!-- Button zum Umschalten der Ansichten -->
    <div v-if="entries.length > 0" class="view-toggle-icons">
      <span 
        :class="{ active: isTimelineView }" 
        @click="toggleTimelineView" 
        :title="$t('timeline.view_timeline')"
      >📋</span>
      <span 
        :class="{ active: isGridView }" 
        @click="toggleView" 
        :title="$t('timeline.view_images')"
      >🖼️</span>
      <span 
        :class="{ active: isMultimediaView }" 
        @click="toggleMultimediaView" 
        :title="$t('timeline.view_multimedia')"
      >🎥</span>
    </div>

    <!-- Gitteransicht der Bilder in Kartenform -->
    <div v-if="isGridView" class="grid-view">
      <div v-for="(file, index) in imageFiles" :key="index" class="grid-item card">
        <img :src="file.thumbnail_url" :alt="file.name" class="card-img-top grid-thumbnail" />
        <div class="card-body">
          <h5 class="card-title">{{ file.name }}</h5>
          <p class="card-text">
            <span>{{ file.extension.toUpperCase() }} - {{ file.size_kb }} KB</span><br>
            <span v-if="file.resolution">{{ $t('timeline.resolution') }}: {{ file.resolution }}</span><br>
            <span v-if="file.ai_tags">Tags: 
          <span v-for="(tag, idx) in normalizeAiTags(file.ai_tags)" :key="idx" class="badge badge-primary" style="background-color: green;">
            {{ tag }}
            <span @click="removeTag(file, idx)" class="badge-delete">x</span>
          </span>
        </span>
          </p>
          <a :href="file.url" download class="btn btn-primary">{{ $t('common.download') }}</a>
        </div>
      </div>
    </div>

    <!-- Rasteransicht für Multimedia-Dateien -->
    <div v-if="isMultimediaView" class="grid-view">
      <div v-for="(file, index) in multimediaFiles" :key="index" class="grid-item card">
        <div class="card-body">
          <h5 class="card-title">{{ file.name }}</h5>
          <p class="card-text">
            <span>{{ file.extension.toUpperCase() }} - {{ file.size_kb }} KB</span><br>
            <span v-if="file.duration">{{ $t('timeline.duration') }}: {{ file.duration }}</span><br>
            <span v-if="file.resolution">{{ $t('timeline.resolution') }}: {{ file.resolution }}</span>
          </p>
          <button @click="togglePlayer(index)" class="btn btn-primary">{{ $t('timeline.preview') }}</button>
          <div v-if="activePlayerIndex === index">
            <audio v-if="file.extension === 'mp3'" :src="file.url" controls class="media-player"></audio>
            <video v-if="file.extension === 'mp4'" :src="file.url" controls class="media-player"></video>
          </div>
          <a :href="file.url" download class="btn btn-secondary mt-2">{{ $t('common.download') }}</a>
        </div>
      </div>
    </div>

    <!-- Timeline-Ansicht -->
    <div v-if="isTimelineView && entries.length > 0" class="timeline">
      <div v-for="entry in entries" :key="entry.id" class="timeline-entry">
        <div class="timeline-marker"></div>
        <div class="timeline-content">
          <h5 class="timeline-entry-title">{{ entry.type }}</h5>
          <p>{{ entry.description }}</p>
          <p v-if="entry.deadline"><strong>{{ $t('timeline.deadline') }}:</strong> {{ formatDate(entry.deadline) }}</p>
          <ul v-if="entry.checklist && entry.checklist.length" class="checklist">
            <li v-for="(item, index) in entry.checklist" :key="index">
              <input type="checkbox" :checked="item.completed" @change="toggleChecklistItem(entry.id, index, item.completed)">
              {{ item.text }}
            </li>
          </ul>
          <div v-if="entry.file_info && Array.isArray(entry.file_info) && entry.file_info.length" class="file-list">
            <div v-for="(file, index) in entry.file_info" :key="index" class="file-item">
              <div v-if="file.thumbnail_url" class="file-thumbnail">
                <img :src="file.thumbnail_url" alt="Thumbnail" class="thumbnail-img" />
              </div>
              <div class="file-icon">
                <img :src="getFileIconSrc(file.extension)" alt="file icon" class="file-icon-img" />
              </div>
              <div class="file-details">
                <a :href="`${file.url}`" target="_blank" class="file-name">
                  {{ file.name }}
                </a>
                <span class="file-info">{{ file.extension.toUpperCase() }} - {{ file.size_kb }} KB<span v-if="file.duration" class="file-duration"> | {{ $t('timeline.duration') }}: {{ file.duration }}</span>
                <span v-if="file.resolution" class="file-resolution"> | {{ $t('timeline.resolution') }}: {{ file.resolution }}</span></span>
                
              </div>
            </div>
          </div>
          <p><small>{{ $t('timeline.created_at') }}: {{ formatDateTime(entry.created_at) }}</small></p>
          <button @click="deleteEntry(entry.id)" class="btn btn-danger btn-sm">{{ $t('common.delete') }}</button>
        </div>
      </div>
    </div>
    <div v-if="isTimelineView && entries.length < 1">
      <p>{{ $t('timeline.no_entries') }}</p>
    </div>

    <h4 class="mt-5">{{ $t('timeline.add_new_entry') }}</h4>
    <form @submit.prevent="createEntry" class="timeline-form">
      <div class="mb-3">
        <label for="type" class="form-label">{{ $t('timeline.entry_type') }}</label>
        <select v-model="newEntry.type" id="type" class="form-select" @change="toggleFields">
          <option value="Notiz">{{ $t('timeline.note') }}</option>
          <option value="Aufgabe">{{ $t('timeline.task') }}</option>
          <option value="Checkliste">{{ $t('timeline.checklist') }}</option>
          <option value="Dateianhang">{{ $t('timeline.file_attachment') }}</option>
        </select>
      </div>

      <div class="mb-3">
        <label for="description" class="form-label">{{ $t('timeline.description') }}</label>
        <textarea v-model="newEntry.description" id="description" class="form-control" required></textarea>
      </div>

      <div v-if="showDeadlineField" class="mb-3">
        <label for="deadline" class="form-label">{{ $t('timeline.deadline') }}</label>
        <input v-model="newEntry.deadline" type="date" id="deadline" class="form-control">
      </div>

      <div v-if="showChecklistFields" class="mb-3">
        <label class="form-label">{{ $t('timeline.checklist') }}</label>
        <div v-for="(item, index) in newEntry.checklist" :key="index" class="checklist-item mb-2">
          <input v-model="item.text" type="text" class="form-control mb-1" placeholder="{{ $t('timeline.checklist_item') }}">
          <button @click="removeChecklistItem(index)" type="button" class="btn btn-danger btn-sm">X</button>
        </div>
        <button @click="addChecklistItem" type="button" class="btn btn-secondary mt-2">{{ $t('timeline.add_checklist_item') }}</button>
      </div>

      <div v-if="showFileField" class="mb-3">
        <label for="files" class="form-label">{{ $t('timeline.upload_files') }}</label>
        <div 
          class="file-drop-area"
          @dragover.prevent="onDragOver"
          @dragleave.prevent="onDragLeave"
          @drop.prevent="onFileDrop"
          :class="{ dragging: isDragging }"
        >
          <input @change="handleFileUpload" type="file" id="files" class="form-control" multiple>
          <p class="file-drop-message">{{ dragAndDropMessage }}</p>
        </div>
        <ul v-if="newEntry.files.length">
          <li v-for="(file, index) in newEntry.files" :key="index">{{ file.name }}</li>
        </ul>
        <div v-if="uploadProgress > 0 && uploadProgress < 100">
          <p>{{ $t('timeline.upload_progress') }}: {{ uploadProgress }}%</p>
          <div class="progress">
            <div 
              class="progress-bar" 
              :style="{ width: uploadProgress + '%' }"
              role="progressbar"
              :aria-valuenow="uploadProgress"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
        <div v-if="uploadProgress === 100">
          <p>{{ $t('timeline.upload_complete') }}</p>
        </div>
      </div>

      <button type="submit" class="btn btn-primary" :disabled="isUploading">
        <span v-if="isUploading">
          <i class="fa fa-spinner fa-spin"></i> {{ $t('timeline.uploading') }}...
        </span>
        <span v-else>{{ $t('timeline.add_entry') }}</span>
      </button>
    </form>
  </div>
</template>



<script>
export default {
  props: {
    entityType: { type: String, required: true },
    entityId: { type: Number, required: true }
  },
  data() {
    return {
      entries: [],
      newEntry: {
        type: 'Notiz',
        description: '',
        deadline: '',
        checklist: [],
        files: [] // Mehrere Dateien
      },
      isGridView: false,
      isMultimediaView: false, // Zustand für die Multimedia-Ansicht
      isTimelineView: true,     // Zustand für die Timeline-Ansicht (Standard)
      activePlayerIndex: null, // Index des aktuell aktiven Players
      uploadProgress: 0, // Fortschrittsanzeige für den Upload
      isUploading: false, // Zustand des Uploads
      showDeadlineField: false,
      showChecklistFields: false,
      showFileField: false,
      isDragging: false,
      dragAndDropMessage: this.$t('timeline.drag_and_drop')
    };
  },
  computed: {
    imageFiles() {
      return this.entries.flatMap(entry =>
        entry.file_info.filter(file =>
          ['jpg', 'jpeg', 'png', 'gif'].includes(file.extension.toLowerCase())
        )
      );
    }, 
    multimediaFiles() {
      return this.entries.flatMap(entry =>
        entry.file_info.filter(file =>
          ['mp3', 'mp4'].includes(file.extension.toLowerCase())
        )
      );
    }
  },
  methods: {
    normalizeAiTags(aiTags) {
      // Wenn ai_tags ein Array ist, gib es zurück
      if (Array.isArray(aiTags)) {
        return aiTags;
      }

      // Wenn ai_tags ein Objekt mit numerischen Schlüsseln ist
      if (typeof aiTags === 'object' && aiTags !== null) {
        return Object.values(aiTags);
      }

      // Falls ai_tags kein Array oder Objekt ist, gib ein leeres Array zurück
      return [];
    },
    toggleView() {
      this.isGridView = true;
      this.isMultimediaView = false;
      this.isTimelineView = false;
    },
    toggleMultimediaView() {
      this.isMultimediaView = true;
      this.isGridView = false;
      this.isTimelineView = false;
    },
    toggleTimelineView() {
      this.isTimelineView = true;
      this.isGridView = false;
      this.isMultimediaView = false;
    },
    togglePlayer(index) {
      this.activePlayerIndex = this.activePlayerIndex === index ? null : index;
    },
    async loadEntries() {
      const token = localStorage.getItem('authToken');
      try {
        const response = await fetch(`https://kompaktcrm.de/beta/timeline_api.php?entity_type=${this.entityType}&entity_id=${this.entityId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        this.entries = data.map(entry => ({
          ...entry,
          file_info: entry.file_info || []
        }));
      } catch (error) {
        console.error('Fehler beim Laden der Timeline-Einträge:', error);
      }
    },
    async createEntry() {
      this.isUploading = true; // Upload startet
      const token = localStorage.getItem('authToken');
      const formData = new FormData();

      formData.append('entity_type', this.entityType);
      formData.append('entity_id', this.entityId);
      formData.append('type', this.newEntry.type);
      formData.append('description', this.newEntry.description);
      if (this.newEntry.deadline) {
        formData.append('deadline', this.newEntry.deadline);
      }
      if (this.newEntry.checklist.length > 0) {
        this.newEntry.checklist.forEach((item, index) => {
          formData.append(`checklist[${index}][text]`, item.text);
          formData.append(`checklist[${index}][completed]`, item.completed);
        });
      }
      if (this.newEntry.files.length > 0) {
        this.newEntry.files.forEach((file, index) => {
          formData.append(`file[${index}]`, file);
        });
      }

      try {
        const response = await fetch('https://kompaktcrm.de/beta/timeline_api.php', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`
          },
          body: formData
        });

        const result = await response.json();
        if (result.success) {
          this.newEntry = {
            type: 'Notiz',
            description: '',
            deadline: '',
            checklist: [],
            files: []
          };
          this.uploadProgress = 0; // Fortschrittsanzeige zurücksetzen
          this.loadEntries();
        } else {
          alert('Fehler: ' + result.error);
        }
      } catch (error) {
        console.error('Fehler beim Erstellen des Timeline-Eintrags:', error);
      } finally {
        this.isUploading = false; // Upload beendet
      }
    },
    async deleteEntry(entryId) {
      const token = localStorage.getItem('authToken');
      if (confirm(this.$t('timeline.confirm_delete'))) {
        try {
          const response = await fetch(`https://kompaktcrm.de/beta/timeline_api.php?id=${entryId}`, {
            method: 'DELETE',
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          const result = await response.json();
          if (result.success) {
            this.loadEntries();
          } else {
            alert('Fehler: ' + result.error);
          }
        } catch (error) {
          console.error('Fehler beim Löschen des Timeline-Eintrags:', error);
        }
      }
    },
    toggleFields() {
      this.showDeadlineField = this.newEntry.type === 'Aufgabe';
      this.showChecklistFields = this.newEntry.type === 'Checkliste';
      this.showFileField = this.newEntry.type === 'Dateianhang';
    },
    addChecklistItem() {
      this.newEntry.checklist.push({ text: '', completed: false });
    },
    removeChecklistItem(index) {
      this.newEntry.checklist.splice(index, 1);
    },
    onDragOver() {
      this.isDragging = true;
      this.dragAndDropMessage = this.$t('timeline.drag_and_drop_active');
    },
    onDragLeave() {
      this.isDragging = false;
      this.dragAndDropMessage = this.$t('timeline.drag_and_drop');
    },
    onFileDrop(event) {
      this.isDragging = false;
      const files = event.dataTransfer.files;
      this.handleFileUpload({ target: { files } });
    },
    handleFileUpload(event) {
      this.newEntry.files = Array.from(event.target.files);
      this.dragAndDropMessage = `${this.newEntry.files.length} ${this.$t('timeline.files_selected')}`;
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString();
    },
    formatDateTime(datetime) {
      return new Date(datetime).toLocaleString();
    },
    getFileIconSrc(extension) {
      switch (extension.toLowerCase()) {
        case 'pdf':
          return require('@/assets/pdf-file-format-symbol.png');
        case 'doc':
        case 'docx':
          return require('@/assets/doc-file-format.png');
        case 'jpg':
        case 'jpeg':
          return require('@/assets/jpg-file-format-symbol.png');
        case 'png':
          return require('@/assets/png-file-format-symbol.png');
        case 'mp3':
          return require('@/assets/mp3-file.png');
        case 'wav':
          return require('@/assets/wav-file.png');
        case 'mp4':
          return require('@/assets/mp4-file.png');
        default:
          return require('@/assets/file.png'); // Fallback-Icon
      }
    },
    async toggleChecklistItem(entryId, itemIndex, completed) {
      const token = localStorage.getItem('authToken');
      try {
        const response = await fetch('https://kompaktcrm.de/beta/timeline_api.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            action: 'toggleChecklistItem',
            id: entryId,
            itemIndex: itemIndex,
            completed: !completed
          })
        });
        const result = await response.json();
        if (!result.success) {
          alert('Fehler: ' + result.error);
        } else {
          this.loadEntries();
        }
      } catch (error) {
        console.error('Fehler beim Umschalten des Checklisten-Items:', error);
      }
    }
  },
  mounted() {
    this.loadEntries();
  }
};
</script>


<style scoped>
/* Timeline container */
.timeline-container {
  background-color: #f9f9f9;
  padding: 40px 20px;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  margin-bottom: 20px;
}

/* Title */
.timeline-title {
  margin-bottom: 20px;
  font-weight: bold;
  color: #007bff;
}

/* Timeline style */
.timeline {
  position: relative;
  padding-left: 50px;
  margin-bottom: 40px;
}

.timeline:before {
  content: '';
  position: absolute;
  top: 0;
  left: 36px;
  bottom: 0;
  width: 4px;
  background-color: #007bff;
  border-radius: 2px;
}

.timeline-entry {
  position: relative;
  margin-bottom: 20px;
}

.timeline-marker {
  position: absolute;
  left: -22px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 4px solid #007bff;
  border-radius: 50%;
  z-index: 1;
}

.timeline-content {
  padding: 10px 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-left: 20px;
}

.timeline-entry-title {
  font-size: 18px;
  margin-bottom: 5px;
  color: #007bff;
}

/* Checklist style */
.checklist {
  list-style-type: none;
  padding-left: 0;
  margin-top: 10px;
}

.checklist-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checklist-item input[type="text"] {
  flex: 1;
  margin-right: 10px;
}

/* File list style */
.file-list {
  margin-top: 15px;
}

.file-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.file-icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.file-icon-img {
  max-width: 100%;
  max-height: 100%;
}

.file-details {
  flex: 1;
  overflow: hidden;
}

.file-name {
  font-weight: bold;
  color: #333;
  text-decoration: none;
}

.file-info {
  display: block;
  color: #666;
  font-size: 12px;
}
/* Neue CSS-Klassen für Thumbnails */
.file-thumbnail {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  overflow: hidden;
  border-radius: 5px;
  transition: transform 0.3s ease, width 0.3s ease, height 0.3s ease;
}

.thumbnail-img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.file-thumbnail:hover {
  width: 150px;
  height: 150px;
  transform: scale(1.5);
  z-index: 1000;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

/* Form styles */
.timeline-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.timeline-form .form-label {
  font-weight: bold;
  color: #333;
}

.timeline-form .form-control,
.timeline-form .form-select {
  border-radius: 5px;
  border: 1px solid #ced4da;
}

.timeline-form button[type="submit"] {
  width: 100%;
}

.timeline-form button.btn-secondary {
  margin-top: 10px;
}

.file-drop-area {
  border: 2px dashed #007bff;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.file-drop-area.dragging {
  background-color: #e9f7ff;
}

.file-drop-message {
  color: #007bff;
}

.file-drop-message.dragging {
  color: #0056b3;
}

.progress {
  height: 20px;
  background-color: #f1f1f1;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;
}

.progress-bar {
  background-color: #007bff;
  height: 100%;
}

.grid-view {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

@media (min-width: 768px) {
  .grid-view {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 992px) {
  .grid-view {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1200px) {
  .grid-view {
    grid-template-columns: repeat(4, 1fr);
  }
}

.grid-item.card {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.grid-item.card:hover {
  transform: scale(1.05);
}

.grid-thumbnail {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.card-body {
  padding: 15px;
}

.card-title {
  font-size: 16px;
  margin-bottom: 10px;
  color: #007bff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-text {
  font-size: 14px;
  color: #666;
  margin-bottom: 15px;
}

.btn-primary, .btn-secondary {
  width: 100%;
  text-align: center;
}

.media-player {
  margin-top: 10px;
  width: 100%;
}

.view-toggle-icons {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.view-toggle-icons span {
  font-size: 20px;
  color: #007bff;
  cursor: pointer;
  padding: 8px 12px;
  border: 2px solid #007bff;
  border-radius: 4px;
  background-color: #ffffff;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.view-toggle-icons span:hover {
  background-color: #e6f7ff;
  color: #0056b3;
  border-color: #0056b3;
}

.view-toggle-icons span.active {
  background-color: #007bff;
  color: #ffffff;
  border-color: #007bff;
  font-weight: bold;
}

</style>
