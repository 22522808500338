<template>
  <div class="container mt-5">
    <h2 class="text-primary mb-4">{{ $t('invoice.edit_invoice') }}</h2>
    <form @submit.prevent="updateInvoice" class="card p-4 shadow-sm">
      <div class="mb-3">
        <label for="invoice_number" class="form-label">{{ $t('invoice.invoice_number') }}</label>
        <input v-model="invoice.invoice_number" type="text" class="form-control" id="invoice_number" readonly>
      </div>

      <div class="mb-3">
        <label for="order_id" class="form-label">
          {{ $t('invoice.order') }}
          <span v-if="loadingOrders" class="spinner-border spinner-border-sm text-primary ms-2" role="status"></span>
        </label>
        <select v-model="invoice.order_id" id="order_id" class="form-control">
          <option v-for="order in orders" :value="order.id" :key="order.id">{{ order.title }}  ({{ order.order_number }})</option>
        </select>
      </div>

      <div class="mb-3">
        <label for="company_id" class="form-label">
          {{ $t('invoice.company') }}
          <span v-if="loadingCompanies" class="spinner-border spinner-border-sm text-primary ms-2" role="status"></span>
        </label>
        <select v-model="invoice.company_id" id="company_id" class="form-control">
          <option v-for="company in companies" :value="company.id" :key="company.id">{{ company.name }} (ID: {{ company.id }})</option>
        </select>
      </div>

      <div class="mb-3">
        <label for="contact_id" class="form-label">
          {{ $t('invoice.contact') }}
          <span v-if="loadingContacts" class="spinner-border spinner-border-sm text-primary ms-2" role="status"></span>
        </label>
        <select v-model="invoice.contact_id" id="contact_id" class="form-control">
          <option v-for="contact in contacts" :value="contact.id" :key="contact.id">{{ contact.first_name }} {{ contact.last_name }} (ID: {{ contact.id }})</option>
        </select>
      </div>

      <div class="mb-3">
        <label for="issue_date" class="form-label">{{ $t('invoice.issue_date') }}</label>
        <input v-model="invoice.issue_date" type="date" class="form-control" id="issue_date" required>
      </div>

      <div class="mb-3">
        <label for="due_date" class="form-label">{{ $t('invoice.due_date') }}</label>
        <input v-model="invoice.due_date" type="date" class="form-control" id="due_date" required>
      </div>

      <div class="mb-3">
        <label for="billing_address" class="form-label">{{ $t('invoice.billing_address') }}</label>
        <textarea v-model="invoice.billing_address" class="form-control" id="billing_address" required></textarea>
      </div>

      <div class="mb-3">
        <label for="tax_rate" class="form-label">{{ $t('invoice.tax_rate') }}</label>
        <input v-model="invoice.tax_rate" type="number" class="form-control" id="tax_rate" step="0.01">
      </div>

      <div class="form-check mb-3">
        <input v-model="invoice.small_business" type="checkbox" class="form-check-input" id="small_business">
        <label class="form-check-label" for="small_business">{{ $t('invoice.small_business') }}</label>
      </div>

      <div class="mb-3">
        <label for="discount_rate" class="form-label">{{ $t('invoice.discount_rate') }}</label>
        <input v-model="invoice.discount_rate" type="number" class="form-control" id="discount_rate" step="0.01">
      </div>

      <div class="mb-3">
        <label for="currency" class="form-label">{{ $t('invoice.currency') }}</label>
        <input v-model="invoice.currency" type="text" class="form-control" id="currency" required>
      </div>

      <div class="mb-3">
        <label for="language" class="form-label">{{ $t('invoice.language') }}</label>
        <select v-model="invoice.language" id="language" class="form-control">
          <option value="de">Deutsch</option>
          <option value="en">Englisch</option>
          <option value="es">Spanisch</option>
          <option value="fr">Französisch</option>
          <option value="pt">Portugiesisch</option>
        </select>
      </div>

      <div class="mb-3">
        <label for="payment_terms" class="form-label">{{ $t('invoice.payment_terms') }}</label>
        <textarea v-model="invoice.payment_terms" class="form-control" id="payment_terms"></textarea>
      </div>

      <div class="mb-3">
        <label for="status" class="form-label">{{ $t('invoice.status') }}</label>
        <select v-model="invoice.status" id="status" class="form-control" required>
          <option value="Offen">{{ $t('invoice.status_options.open') }}</option>
          <option value="Beglichen">{{ $t('invoice.status_options.paid') }}</option>
          <option value="Überfällig">{{ $t('invoice.status_options.overdue') }}</option>
        </select>
      </div>

      <div class="mb-3">
        <label for="items" class="form-label">{{ $t('invoice.invoice_items') }}</label>
        <div class="mb-3">
          <button type="button" class="btn btn-secondary" @click="addItem">{{ $t('invoice.add_item') }}</button>
        </div>
        <div id="invoiceItemsContainer">
          <div v-for="(item, index) in invoice.items" :key="index" class="row invoice-item mb-2">
            <div class="col-md-1">
              <input v-model="item.position" type="text" class="form-control" :name="'items[' + index + '][position]'" :placeholder="$t('invoice.position')">
            </div>
            <div class="col-md-3">
              <input v-model="item.name" type="text" class="form-control" :name="'items[' + index + '][name]'" :placeholder="$t('invoice.item_name')">
            </div>
            <div class="col-md-4">
              <input v-model="item.description" type="text" class="form-control" :name="'items[' + index + '][description]'" :placeholder="$t('invoice.description')">
            </div>
            <div class="col-md-1">
              <input v-model="item.quantity" @input="updateTotalPrice(index)" type="number" class="form-control" :name="'items[' + index + '][quantity]'" :placeholder="$t('invoice.quantity')" min="1">
            </div>
            <div class="col-md-2">
              <input v-model="item.unit_price" @input="updateTotalPrice(index)" type="number" class="form-control" :name="'items[' + index + '][unit_price]'" :placeholder="$t('invoice.unit_price')" step="0.01">
            </div>
            <div class="col-md-1">
              <input v-model="item.total_price" type="number" class="form-control" :name="'items[' + index + '][total_price]'" :placeholder="$t('invoice.total_price')" readonly>
            </div>
            <div class="col-md-1 align-self-end">
              <button type="button" class="btn btn-danger" @click="removeItem(index)">X</button>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <button type="submit" class="btn btn-success me-2">{{ $t('common.save') }}</button>
        <button type="button" class="btn btn-secondary" @click="cancelEdit">{{ $t('common.cancel') }}</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      invoice: {
        id: null,
        invoice_number: '',
        company_id: '',
        contact_id: '',
        order_id: '',
        issue_date: '',
        due_date: '',
        billing_address: '',
        tax_rate: 19.00,
        small_business: false,
        discount_rate: 0.00,
        items: [],
        payment_terms: this.$t('invoice.payment_terms_default'),
        currency: 'EUR',
        language: 'de',
        status: 'Offen'
      },
      companies: [],
      contacts: [],
      orders: [],
      loadingCompanies: false,
      loadingContacts: false,
      loadingOrders: false
    };
  },
  methods: {
    async fetchInvoice() {
      const token = localStorage.getItem('authToken');
      const invoiceId = this.$route.params.id;

      try {
        const response = await fetch(`https://kompaktcrm.de/beta/invoices_api.php?id=${invoiceId}`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        if (data.error) {
          alert('Fehler: ' + data.error);
        } else {
          this.invoice = data;
        }
      } catch (error) {
        console.error('Fehler beim Laden der Rechnung:', error);
      }
    },
    async fetchData(retryCount = 0) {
      const token = localStorage.getItem('authToken');
      this.loadingCompanies = true;
      this.loadingContacts = true;
      this.loadingOrders = true;

      try {
        const [companiesRes, contactsRes, ordersRes] = await Promise.all([
          fetch('https://kompaktcrm.de/beta/companies_api.php', {
            headers: { 'Authorization': `Bearer ${token}` }
          }),
          fetch('https://kompaktcrm.de/beta/contacts_api.php', {
            headers: { 'Authorization': `Bearer ${token}` }
          }),
          fetch('https://kompaktcrm.de/beta/orders_api.php', {
            headers: { 'Authorization': `Bearer ${token}` }
          })
        ]);

        const companies = companiesRes.ok ? await companiesRes.json() : null;
        const contacts = contactsRes.ok ? await contactsRes.json() : null;
        const orders = ordersRes.ok ? await ordersRes.json() : null;

        const companiesValid = companies && companies.every(company => company.name);
        const contactsValid = contacts && contacts.every(contact => contact.first_name && contact.last_name);
        const ordersValid = orders && orders.every(order => order.title);

        if (companiesValid && contactsValid && ordersValid) {
          this.companies = companies;
          this.contacts = contacts;
          this.orders = orders;

          this.loadingCompanies = false;
          this.loadingContacts = false;
          this.loadingOrders = false;
        } else {
          throw new Error('Ungültige Daten: Leere oder fehlende Namen');
        }

      } catch (error) {
        console.error('Fehler beim Laden der Daten:', error);

        if (retryCount < 6) {
          setTimeout(() => {
            this.fetchData(retryCount + 1);
          }, 5000);
        }
      } finally {

      }
    },
    addItem() {
      this.invoice.items.push({
        position: '',
        name: '',
        description: '',
        quantity: 1,
        unit_price: 0.00,
        total_price: 0.00
      });
    },
    removeItem(index) {
      this.invoice.items.splice(index, 1);
    },
    updateTotalPrice(index) {
      const item = this.invoice.items[index];
      item.total_price = (item.quantity * item.unit_price).toFixed(2);
    },
    async updateInvoice() {
      const token = localStorage.getItem('authToken');
      try {
        const response = await fetch('https://kompaktcrm.de/beta/invoices_api.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(this.invoice)
        });

        const result = await response.json();
        if (result.success) {
          alert(this.$t('invoice.update_success'));
          this.$router.push({ name: 'InvoiceList' });
        } else {
          alert(this.$t('invoice.update_error') + ': ' + result.error);
        }
      } catch (error) {
        console.error(this.$t('invoice.update_error') + ':', error);
      }
    },
    cancelEdit() {
      this.$router.push({ name: 'InvoiceList' });
    }
  },
  async mounted() {
    await this.fetchInvoice();
    await this.fetchData();
  }
};
</script>

<style scoped>
/* Dein Styles hier */
.card {
  border-radius: 10px;
  background-color: #f8f9fa;
}

h2 {
  font-weight: bold;
}

.form-label {
  font-weight: 600;
}

textarea {
  resize: none;
}

button {
  width: 150px;
}
</style>
