<template>
  <div class="container mt-5">
    <h2 class="text-primary mb-4">{{ $t('invoice.create_new') }}</h2>
    <form @submit.prevent="submitInvoice" class="card p-4 shadow-sm">
      <!--<div class="mb-3">
        <label for="invoiceNumber" class="form-label">{{ $t('invoice.invoice_number') }}</label>
        <input v-model="invoice.invoice_number" type="text" class="form-control" id="invoiceNumber">
      </div>-->
      <div class="mb-3">
        <label for="orderId" class="form-label">{{ $t('invoice.order') }}</label>
        <select v-model="invoice.order_id" class="form-control" id="orderId">
          <option value="" disabled>{{ $t('invoice.select_option') }}</option>
          <option v-for="order in orders" :key="order.id" :value="order.id">{{ order.title }}</option>
        </select>
      </div>
      <div class="mb-3">
        <label for="companyId" class="form-label">{{ $t('invoice.company') }}</label>
        <select v-model="invoice.company_id" class="form-control" id="companyId">
          <option value="" disabled>{{ $t('invoice.select_option') }}</option>
          <option v-for="company in companies" :key="company.id" :value="company.id">{{ company.name }}</option>
        </select>
      </div>
      <div class="mb-3">
        <label for="contactId" class="form-label">{{ $t('invoice.contact') }}</label>
        <select v-model="invoice.contact_id" class="form-control" id="contactId">
          <option value="" disabled>{{ $t('invoice.select_option') }}</option>
          <option v-for="contact in contacts" :key="contact.id" :value="contact.id">{{ contact.first_name }} {{ contact.last_name }}</option>
        </select>
      </div>
      <div class="mb-3">
        <label for="issueDate" class="form-label">{{ $t('invoice.issue_date') }}</label>
        <input v-model="invoice.issue_date" type="date" class="form-control" id="issueDate" required>
      </div>
      <div class="mb-3">
        <label for="dueDate" class="form-label">{{ $t('invoice.due_date') }}</label>
        <input v-model="invoice.due_date" type="date" class="form-control" id="dueDate" required>
      </div>
      <div class="mb-3">
        <label for="status" class="form-label">{{ $t('invoice.status') }}</label>
        <select v-model="invoice.status" id="status" class="form-control" required>
          <option value="Offen">{{ $t('invoice.status_options.open') }}</option>
          <option value="Beglichen">{{ $t('invoice.status_options.paid') }}</option>
          <option value="Überfällig">{{ $t('invoice.status_options.overdue') }}</option>
        </select>
      </div>
      <div class="mb-3">
        <label for="billingAddress" class="form-label">{{ $t('invoice.billing_address') }}</label>
        <textarea v-model="invoice.billing_address" class="form-control" id="billingAddress" required></textarea>
      </div>
      <div class="mb-3">
        <label for="taxRate" class="form-label">{{ $t('invoice.tax_rate') }}</label>
        <input v-model="invoice.tax_rate" type="number" class="form-control" id="taxRate" step="0.01" value="19.00">
      </div>
      <div class="form-check mb-3">
        <input v-model="invoice.small_business" type="checkbox" class="form-check-input" id="smallBusiness">
        <label class="form-check-label" for="smallBusiness">{{ $t('invoice.small_business') }}</label>
      </div>
      <div class="mb-3">
        <label for="currency" class="form-label">{{ $t('invoice.currency') }}</label>
        <input v-model="invoice.currency" type="text" class="form-control" id="currency" value="EUR" required>
      </div>
      <div class="mb-3">
        <label for="language" class="form-label">{{ $t('invoice.language') }}</label>
        <select v-model="invoice.language" class="form-control" id="language">
          <option value="de">Deutsch</option>
          <option value="en">Englisch</option>
          <option value="es">Spanisch</option>
          <option value="fr">Französisch</option>
          <option value="pt">Portugiesisch</option>
        </select>
      </div>
      <div class="mb-3">
        <label for="discountRate" class="form-label">{{ $t('invoice.discount_rate') }}</label>
        <input v-model="invoice.discount_rate" type="number" class="form-control" id="discountRate" step="0.01" value="0.00">
      </div>
      <div class="mb-3">
        <label class="form-label">{{ $t('invoice.invoice_items') }}</label>
        <button type="button" class="btn btn-secondary" @click="addInvoiceItem">{{ $t('invoice.add_item') }}</button>
        <div v-for="(item, index) in invoice.items" :key="index" class="mt-3">
          <div class="row mb-2">
            <div class="col-md-2">
              <label for="itemPosition" class="form-label">{{ $t('invoice.position') }}</label>
              <input v-model="item.position" type="text" class="form-control" :id="'itemPosition' + index" :placeholder="$t('invoice.position')">
            </div>
            <div class="col-md-3">
              <label for="itemName" class="form-label">{{ $t('invoice.item_name') }}</label>
              <input v-model="item.name" type="text" class="form-control" :id="'itemName' + index" :placeholder="$t('invoice.item_name')">
            </div>
            <div class="col-md-3">
              <label for="itemDescription" class="form-label">{{ $t('invoice.description') }}</label>
              <input v-model="item.description" type="text" class="form-control" :id="'itemDescription' + index" :placeholder="$t('invoice.description')">
            </div>
            <div class="col-md-2">
              <label for="itemQuantity" class="form-label">{{ $t('invoice.quantity') }}</label>
              <input v-model="item.quantity" type="number" class="form-control" :id="'itemQuantity' + index" :placeholder="$t('invoice.quantity')" min="1">
            </div>
            <div class="col-md-2">
              <label for="itemUnitPrice" class="form-label">{{ $t('invoice.unit_price') }}</label>
              <input v-model="item.unit_price" type="number" class="form-control" :id="'itemUnitPrice' + index" :placeholder="$t('invoice.unit_price')" step="0.01">
            </div>
          </div>
          <div class="text-end">
            <button type="button" class="btn btn-danger btn-sm" @click="removeInvoiceItem(index)">{{ $t('invoice.remove_item') }}</button>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <label for="paymentTerms" class="form-label">{{ $t('invoice.payment_terms') }}</label>
        <textarea v-model="invoice.payment_terms" class="form-control" id="paymentTerms">{{ $t('invoice.payment_terms_default') }}</textarea>
      </div>
      <div class="mt-4">
        <button type="submit" class="btn btn-success me-2">{{ $t('invoice.save') }}</button>
        <button type="button" class="btn btn-secondary" @click="cancel">{{ $t('invoice.cancel') }}</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      invoice: {
        invoice_number: '',
        order_id: null,
        company_id: null,
        contact_id: null,
        issue_date: '',
        due_date: '',
        billing_address: '',
        tax_rate: 20.00,
        small_business: false,
        discount_rate: 0.00,
        items: [],
        payment_terms: this.$t('invoice.payment_terms_default'),
        currency: 'EUR',
        language: 'de'
      },
      orders: [],
      companies: [],
      contacts: []
    };
  },
  methods: {
    async fetchOrders() {
      const token = localStorage.getItem('authToken');
      try {
        const response = await fetch('https://kompaktcrm.de/beta/orders_api.php', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        this.orders = await response.json();
      } catch (error) {
        console.error(this.$t('error.loading_orders'), error);
      }
    },
    async fetchCompanies() {
      const token = localStorage.getItem('authToken');
      try {
        const response = await fetch('https://kompaktcrm.de/beta/companies_api.php', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        this.companies = await response.json();
      } catch (error) {
        console.error(this.$t('error.loading_companies'), error);
      }
    },
    async fetchContacts() {
      const token = localStorage.getItem('authToken');
      try {
        const response = await fetch('https://kompaktcrm.de/beta/contacts_api.php', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        this.contacts = await response.json();
      } catch (error) {
        console.error(this.$t('error.loading_contacts'), error);
      }
    },
    addInvoiceItem() {
      this.invoice.items.push({
        position: '',
        name: '',
        description: '',
        quantity: 1,
        unit_price: 0.00
      });
    },
    removeInvoiceItem(index) {
      this.invoice.items.splice(index, 1);
    },
    async submitInvoice() {
      const token = localStorage.getItem('authToken');
      try {
        const response = await fetch('https://kompaktcrm.de/beta/invoices_api.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify(this.invoice),
        });
        const data = await response.json();
        if (data.success) {
          alert(this.$t('invoice.success_created'));
          this.$router.push({ name: 'InvoiceList' });
        } else {
          alert(this.$t('error') + ': ' + data.error);
        }
      } catch (error) {
        console.error(this.$t('error.creating_invoice'), error);
      }
    },
    cancel() {
      this.$router.push({ name: 'InvoiceList' });
    }
  },
  mounted() {
    this.fetchOrders();
    this.fetchCompanies();
    this.fetchContacts();
  },
};
</script>

<style scoped>
.card {
  border-radius: 10px;
  background-color: #f8f9fa;
}

h2 {
  font-weight: bold;
}

.form-label {
  font-weight: 600;
}

textarea {
  resize: none;
}

button {
  width: 150px;
}
</style>
