<template>
  <div class="container mt-5">
    <h2 class="text-primary mb-4">{{ $t('order.create_new_order') }}</h2>
    <form @submit.prevent="createOrder" class="card p-4 shadow-sm">
      <div class="mb-3">
        <label for="title" class="form-label">{{ $t('order.title') }}</label>
        <input v-model="order.title" type="text" id="title" class="form-control" required>
      </div>
      <div class="mb-3">
        <label for="description" class="form-label">{{ $t('order.description') }}</label>
        <textarea v-model="order.description" id="description" class="form-control"></textarea>
      </div>
      <div class="mb-3">
        <label for="company_id" class="form-label">{{ $t('order.company') }}</label>
        <select v-model="order.company_id" id="company_id" class="form-control" required>
          <option v-for="company in companies" :value="company.id" :key="company.id">{{ company.name }}</option>
        </select>
      </div>
      <div class="mb-3">
        <label for="contact_id" class="form-label">{{ $t('order.contact') }}</label>
        <select v-model="order.contact_id" id="contact_id" class="form-control" required>
          <option v-for="contact in contacts" :value="contact.id" :key="contact.id">{{ contact.first_name }} {{ contact.last_name }}</option>
        </select>
      </div>
      <div class="mb-3">
        <label for="status" class="form-label">{{ $t('order.status') }}</label>
        <select v-model="order.status" id="status" class="form-control">
          <option value="unqualifiziert">{{ $t('order.status_unqualified') }}</option>
          <option value="qualifiziert">{{ $t('order.status_qualified') }}</option>
          <option value="inBearbeitung">{{ $t('order.status_in_progress') }}</option>
          <option value="abgeschlossen">{{ $t('order.status_completed') }}</option>
          <option value="zurückgestellt">{{ $t('order.status_on_hold') }}</option>
          <option value="verloren">{{ $t('order.status_lost') }}</option>
        </select>
      </div>
      <div class="mb-3">
        <label for="amount" class="form-label">{{ $t('order.amount') }}</label>
        <input v-model="order.amount" type="number" id="amount" class="form-control" step="0.01">
      </div>
      <div class="mb-3">
        <label for="currency" class="form-label">{{ $t('order.currency') }}</label>
        <input v-model="order.currency" type="text" id="currency" class="form-control" value="EUR">
      </div>
      <div class="mb-3">
        <label for="due_date" class="form-label">{{ $t('order.due_date') }}</label>
        <input v-model="order.due_date" type="date" id="due_date" class="form-control">
      </div>
      <div class="mb-4">
        <button type="submit" class="btn btn-success me-2">{{ $t('order.create_order') }}</button>
        <button type="button" class="btn btn-secondary" @click="goBack">{{ $t('common.cancel') }}</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      order: {
        title: '',
        description: '',
        company_id: '',
        contact_id: '',
        status: 'unqualifiziert',
        amount: '',
        currency: 'EUR',
        due_date: ''
      },
      companies: [],
      contacts: []
    };
  },
  methods: {
    fetchCompaniesAndContacts() {
      const token = localStorage.getItem('authToken');
      fetch('https://kompaktcrm.de/beta/companies_api.php', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => response.json())
      .then(data => {
        this.companies = data;
      });
      
      fetch('https://kompaktcrm.de/beta/contacts_api.php', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => response.json())
      .then(data => {
        this.contacts = data;
      });
    },
    createOrder() {
      const token = localStorage.getItem('authToken');
      fetch('https://kompaktcrm.de/beta/orders_api.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(this.order)
      })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          this.$router.push({ name: 'OrderList' });
        } else {
          console.error(this.$t('order.create_error'), data.error);
        }
      })
      .catch(error => {
        console.error(this.$t('order.create_error'), error);
      });
    },
    goBack() {
      this.$router.push({ name: 'OrderList' });
    }
  },
  mounted() {
    this.fetchCompaniesAndContacts();
  }
};
</script>

<style scoped>
.card {
  border-radius: 10px;
  background-color: #f8f9fa;
}

h2 {
  font-weight: bold;
}

.form-label {
  font-weight: 600;
}

textarea {
  resize: none;
}

button {
  width: 150px;
}
</style>
