<template>
  <div class="container mt-5">
    <h2 class="text-primary mb-4">{{ $t('contact_create.title') }}</h2>
    <form @submit.prevent="createContact" class="card p-4 shadow-sm">
      
      <!-- Persönliche Informationen -->
      <h4 class="text-secondary mb-3">{{ $t('contact_create.personal_information') }}</h4>
      <div class="row mb-3">
        <div class="col-md-4">
          <label for="salutation" class="form-label">{{ $t('contact_create.salutation') }}</label>
          <input v-model="contact.salutation" type="text" id="salutation" class="form-control" required>
        </div>
        <div class="col-md-4">
          <label for="title" class="form-label">{{ $t('contact_create.title_label') }}</label>
          <input v-model="contact.title" type="text" id="title" class="form-control">
        </div>
        <div class="col-md-4">
          <label for="gender" class="form-label">{{ $t('contact_create.gender') }}</label>
          <select v-model="contact.gender" id="gender" class="form-control">
            <option value="m">{{ $t('contact_create.male') }}</option>
            <option value="w">{{ $t('contact_create.female') }}</option>
            <option value="d">{{ $t('contact_create.diverse') }}</option>
          </select>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <label for="first_name" class="form-label">{{ $t('contact_create.first_name') }}</label>
          <input v-model="contact.first_name" type="text" id="first_name" class="form-control" required>
        </div>
        <div class="col-md-6">
          <label for="last_name" class="form-label">{{ $t('contact_create.last_name') }}</label>
          <input v-model="contact.last_name" type="text" id="last_name" class="form-control" required>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <label for="birthdate" class="form-label">{{ $t('contact_create.birthdate') }}</label>
          <input v-model="contact.birthdate" type="date" id="birthdate" class="form-control">
        </div>
        <div class="col-md-6">
          <label for="job_title" class="form-label">{{ $t('contact_create.job_title') }}</label>
          <input v-model="contact.job_title" type="text" id="job_title" class="form-control">
        </div>
      </div>
      
      <!-- Kontaktinformationen -->
      <h4 class="text-secondary mb-3">{{ $t('contact_create.contact_information') }}</h4>
      <div class="row mb-3">
        <div class="col-md-6">
          <label for="email" class="form-label">{{ $t('contact_create.email') }}</label>
          <input v-model="contact.email" type="email" id="email" class="form-control" required>
        </div>
        <div class="col-md-6">
          <label for="secondary_email" class="form-label">{{ $t('contact_create.secondary_email') }}</label>
          <input v-model="contact.secondary_email" type="email" id="secondary_email" class="form-control">
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <label for="phone" class="form-label">{{ $t('contact_create.phone') }}</label>
          <input v-model="contact.phone" type="text" id="phone" class="form-control">
        </div>
        <div class="col-md-6">
          <label for="mobile" class="form-label">{{ $t('contact_create.mobile') }}</label>
          <input v-model="contact.mobile" type="text" id="mobile" class="form-control">
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-12">
          <label for="linkedin_profile" class="form-label">{{ $t('contact_create.linkedin_profile') }}</label>
          <input v-model="contact.linkedin_profile" type="url" id="linkedin_profile" class="form-control">
        </div>
      </div>
      
      <!-- Adressinformationen -->
      <h4 class="text-secondary mb-3">{{ $t('contact_create.address_information') }}</h4>
      <div class="row mb-3">
        <div class="col-md-6">
          <label for="street_address" class="form-label">{{ $t('contact_create.street_address') }}</label>
          <input v-model="contact.street_address" type="text" id="street_address" class="form-control">
        </div>
        <div class="col-md-6">
          <label for="address_addition" class="form-label">{{ $t('contact_create.address_addition') }}</label>
          <input v-model="contact.address_addition" type="text" id="address_addition" class="form-control">
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-4">
          <label for="postal_code" class="form-label">{{ $t('contact_create.postal_code') }}</label>
          <input v-model="contact.postal_code" type="text" id="postal_code" class="form-control">
        </div>
        <div class="col-md-4">
          <label for="city" class="form-label">{{ $t('contact_create.city') }}</label>
          <input v-model="contact.city" type="text" id="city" class="form-control">
        </div>
        <div class="col-md-4">
          <label for="state" class="form-label">{{ $t('contact_create.state') }}</label>
          <input v-model="contact.state" type="text" id="state" class="form-control">
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <label for="country" class="form-label">{{ $t('contact_create.country') }}</label>
          <input v-model="contact.country" type="text" id="country" class="form-control">
        </div>
        <div class="col-md-6">
          <label for="customer_number" class="form-label">{{ $t('contact_create.customer_number') }}</label>
          <input v-model="contact.customer_number" type="text" id="customer_number" class="form-control">
        </div>
      </div>
      
      <!-- Firmeninformationen -->
      <h4 class="text-secondary mb-3">{{ $t('contact_create.company_information') }}</h4>
      <div class="row mb-3">
        <div class="col-md-6">
          <label for="company_id" class="form-label">{{ $t('contact_create.company') }}</label>
          <select v-model="contact.company_id" id="company_id" class="form-control" @change="handleCompanyChange">
            <option value="">{{ $t('contact_create.no_company') }}</option>
            <option v-for="company in companies" :key="company.id" :value="company.id">{{ company.name }}</option>
          </select>
        </div>
        <div class="col-md-6" v-if="contact.company_id">
          <label for="department" class="form-label">{{ $t('contact_create.department') }}</label>
          <input v-model="contact.department" type="text" id="department" class="form-control">
        </div>
      </div>
      <div class="row mb-3" v-if="contact.company_id">
        <div class="col-md-6">
          <label for="position" class="form-label">{{ $t('contact_create.position') }}</label>
          <input v-model="contact.position" type="text" id="position" class="form-control">
        </div>
      </div>

      <!-- Weitere Informationen -->
      <h4 class="text-secondary mb-3">{{ $t('contact_create.additional_information') }}</h4>
      <div class="row mb-3">
        <div class="col-md-6">
          <label for="languages" class="form-label">{{ $t('contact_create.languages') }}</label>
          <input v-model="contact.languages" type="text" id="languages" class="form-control">
        </div>
        <div class="col-md-6">
          <label for="reference_sources" class="form-label">{{ $t('contact_create.reference_sources') }}</label>
          <input v-model="contact.reference_sources" type="text" id="reference_sources" class="form-control">
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-12">
          <label for="expectations" class="form-label">{{ $t('contact_create.expectations') }}</label>
          <textarea v-model="contact.expectations" id="expectations" class="form-control" rows="3"></textarea>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-12">
          <label for="availability" class="form-label">{{ $t('contact_create.availability') }}</label>
          <input v-model="contact.availability" type="text" id="availability" class="form-control">
        </div>
      </div>
      
      <div class="mb-3">
        <label for="description" class="form-label">{{ $t('contact_create.description') }}</label>
        <textarea v-model="contact.description" id="description" class="form-control" rows="3"></textarea>
      </div>
      <div class="mt-4">
        <button type="submit" class="btn btn-success me-2">{{ $t('contact_create.create') }}</button>
        <button type="button" class="btn btn-secondary" @click="goBack">{{ $t('contact_create.cancel') }}</button>
      </div>
    </form>
  </div>
</template>


<script>
export default {
  data() {
    return {
      contact: {
        salutation: '',
        title: '',
        first_name: '',
        last_name: '',
        email: '',
        secondary_email: '',
        phone: '',
        mobile: '',
        linkedin_profile: '',
        street_address: '',
        address_addition: '',
        postal_code: '',
        city: '',
        state: '',
        country: '',
        customer_number: '',
        description: '',
        company_id: '',
        department: '',
        position: '',
        expectations: '',
        languages: '',
        reference_sources: '',
        availability: '',
        gender: '',
        birthdate: '',
        job_title: ''
      },
      companies: [], // Array zur Speicherung der verfügbaren Firmen
    };
  },
  methods: {
    async createContact() {
      try {
        const token = localStorage.getItem('authToken');
        const response = await fetch('https://kompaktcrm.de/beta/contacts_api.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify(this.contact),
        });
        const data = await response.json();
        if (data.success) {
          this.$router.push({ name: 'ContactList' });
        } else {
          console.error('Fehler beim Erstellen des Kontakts:', data.error);
        }
      } catch (error) {
        console.error('Fehler beim Erstellen des Kontakts:', error);
      }
    },
    async fetchCompanies() {
      try {
        const token = localStorage.getItem('authToken');
        const response = await fetch('https://kompaktcrm.de/beta/companies_api.php', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        const data = await response.json();
        this.companies = data;
      } catch (error) {
        console.error('Fehler beim Laden der Firmen:', error);
      }
    },
    handleCompanyChange() {
      if (!this.contact.company_id) {
        this.contact.department = '';
        this.contact.position = '';
      }
    },
    goBack() {
      this.$router.push({ name: 'ContactList' });
    },
  },
  mounted() {
    this.fetchCompanies();
  },
};
</script>

<style scoped>
/* Stil für Kontaktbearbeitung */
.card {
  border-radius: 10px;
  background-color: #f8f9fa;
}

h2, h4 {
  font-weight: bold;
}

.form-label {
  font-weight: 600;
}

textarea {
  resize: none;
}

button {
  width: 150px;
}
</style>
