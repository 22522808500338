<template>
  <div class="container mt-5">
    <div class="d-flex flex-column flex-md-row justify-content-between mb-3">
      <h2 class="text-primary mb-4">{{ $t('timetracking.title') }}</h2>
      <div class="d-flex flex-column flex-md-row">
        <button class="btn btn-primary mb-2" @click="exportTimes">{{ $t('timetracking.export_times') }}</button>
      </div>
    </div>

    <div class="timetracking-container">
      <div class="current-tracking">
        <h4>{{ $t('timetracking.current_tracking') }}</h4>
        <div v-if="isTracking" class="tracking-active">
          <p>{{ $t('timetracking.started_at') }}: <strong>{{ formatDateTime(startTime) }}</strong></p>
          <p>{{ $t('timetracking.running_for') }}: <strong>{{ formatTime(elapsedTime) }}</strong></p>
          <button @click="stopTracking" class="btn btn-danger btn-sm">{{ $t('timetracking.stop_tracking') }}</button>
        </div>
        <div v-else class="tracking-inactive">
          <p>{{ $t('timetracking.no_active_tracking') }}</p>
          <div class="mb-3">
            <label for="manualStartTime" class="form-label">{{ $t('timetracking.manual_start_time') }} ({{ $t('common.optional') }})</label>
            <div class="input-group">
              <input v-model="manualStartTime" type="datetime-local" id="manualStartTime" class="form-control">
              <button @click="setStartTimeToNow" type="button" class="btn btn-secondary">{{ $t('timetracking.now') }}</button>
            </div>
          </div>
          <button @click="startTracking" class="btn btn-primary btn-sm">{{ $t('timetracking.start_tracking') }}</button>
        </div>
      </div>

      <div v-if="unsavedEntries.length" class="unsaved-entries mt-5">
        <h4>{{ $t('timetracking.unsaved_entries') }}</h4>
        <div v-for="(entry, index) in unsavedEntries" :key="index" class="tracking-summary mt-3">
          <h5>{{ $t('timetracking.unsaved_entry') }} {{ index + 1 }}</h5>
          <p>{{ $t('timetracking.started_at') }}: <strong>{{ formatDateTime(entry.startTime) }}</strong></p>
          <p>{{ $t('timetracking.ended_at') }}: <strong>{{ formatDateTime(entry.endTime) }}</strong></p>
          <p>{{ $t('timetracking.total_duration') }}: <strong>{{ formatTime(entry.elapsedTime) }}</strong></p>
          <form @submit.prevent="saveTrackingEntry(index)" class="tracking-summary-form mt-3">
            <div class="mb-3">
              <label for="description" class="form-label">{{ $t('timetracking.description') }}</label>
              <textarea v-model="entry.description" id="description" class="form-control" required></textarea>
            </div>
            <div class="mb-3">
              <label for="entityType" class="form-label">{{ $t('timetracking.assign_to') }}</label>
              <select v-model="entry.entityType" id="entityType" class="form-select" required>
                <option value="Auftrag">{{ $t('entity.order') }}</option>
                <option value="Unternehmen">{{ $t('entity.company') }}</option>
                <option value="Kontakt">{{ $t('entity.contact') }}</option>
              </select>
            </div>
            <div class="mb-3">
              <label for="entityId" class="form-label">{{ $t('timetracking.assignment_id') }}</label>
              <select v-model="entry.entityId" id="entityId" class="form-select" required>
                <option v-if="entry.entityType === 'Auftrag'" v-for="order in orders" :value="order.id" :key="order.id">{{ order.title }}</option>
                <option v-if="entry.entityType === 'Unternehmen'" v-for="company in companies" :value="company.id" :key="company.id">{{ company.name }}</option>
                <option v-if="entry.entityType === 'Kontakt'" v-for="contact in contacts" :value="contact.id" :key="contact.id">{{ contact.first_name }} {{ contact.last_name }}</option>
              </select>
            </div>
            <div class="mb-3">
              <label for="pauseTime" class="form-label">{{ $t('timetracking.pause_time') }} ({{ $t('timetracking.minutes') }})</label>
              <input v-model="entry.pauseTime" type="number" id="pauseTime" class="form-control" required>
            </div>
            <button type="submit" class="btn btn-success">{{ $t('timetracking.save_entry') }}</button>
            <button @click="discardTrackingEntry(index)" type="button" class="btn btn-danger ms-2">{{ $t('timetracking.discard_entry') }}</button>
          </form>
        </div>
      </div>

      <div class="manual-entry mt-5">
        <h4>{{ $t('timetracking.add_manual_entry') }}</h4>
        <form @submit.prevent="addManualEntry" class="manual-entry-form">
          <div class="mb-3">
            <label for="manualDescription" class="form-label">{{ $t('timetracking.description') }}</label>
            <textarea v-model="manualEntry.description" id="manualDescription" class="form-control" required></textarea>
          </div>
          <div class="mb-3">
            <label for="manualEntityType" class="form-label">{{ $t('timetracking.assign_to') }}</label>
            <select v-model="manualEntry.entityType" id="manualEntityType" class="form-select" required>
              <option value="Auftrag">{{ $t('entity.order') }}</option>
              <option value="Unternehmen">{{ $t('entity.company') }}</option>
              <option value="Kontakt">{{ $t('entity.contact') }}</option>
            </select>
          </div>
          <div class="mb-3">
            <label for="manualEntityId" class="form-label">{{ $t('timetracking.assignment_id') }}</label>
            <select v-model="manualEntry.entityId" id="manualEntityId" class="form-select" required>
              <option v-if="manualEntry.entityType === 'Auftrag'" v-for="order in orders" :value="order.id" :key="order.id">{{ order.title }}</option>
              <option v-if="manualEntry.entityType === 'Unternehmen'" v-for="company in companies" :value="company.id" :key="company.id">{{ company.name }}</option>
              <option v-if="manualEntry.entityType === 'Kontakt'" v-for="contact in contacts" :value="contact.id" :key="contact.id">{{ contact.first_name }} {{ contact.last_name }}</option>
            </select>
          </div>
          <div class="mb-3">
            <label for="manualStartTime" class="form-label">{{ $t('timetracking.start_time') }}</label>
            <input v-model="manualEntry.startTime" type="datetime-local" id="manualStartTime" class="form-control" required>
          </div>
          <div class="mb-3">
            <label for="manualEndTime" class="form-label">{{ $t('timetracking.end_time') }}</label>
            <input v-model="manualEntry.endTime" type="datetime-local" id="manualEndTime" class="form-control" required>
          </div>
          <div class="mb-3">
            <label for="manualPauseTime" class="form-label">{{ $t('timetracking.pause_time') }} ({{ $t('timetracking.minutes') }})</label>
            <input v-model="manualEntry.pauseTime" type="number" id="manualPauseTime" class="form-control" required>
          </div>
          <button type="submit" class="btn btn-primary">{{ $t('timetracking.add_entry') }}</button>
        </form>
      </div>

      <div class="tracked-entries mt-5">
        <h4>{{ $t('timetracking.saved_entries') }}</h4>
        <table class="table table-bordered table-hover">
          <thead class="thead-light">
            <tr>
              <th scope="col">{{ $t('timetracking.description') }}</th>
              <th scope="col">{{ $t('timetracking.assignment') }}</th>
              <th scope="col">{{ $t('timetracking.start_time') }}</th>
              <th scope="col">{{ $t('timetracking.end_time') }}</th>
              <th scope="col">{{ $t('timetracking.pause_time') }}</th>
              <th scope="col">{{ $t('timetracking.total_duration') }}</th>
              <th scope="col">{{ $t('common.action') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="entry in savedEntries" :key="entry.id">
              <td>{{ entry.description }}</td>
              <td>{{ entry.entity_type }}: {{ entry.entity_name }}</td>
              <td>{{ formatDateTime(entry.startTime) }}</td>
              <td>{{ formatDateTime(entry.endTime) }}</td>
              <td>{{ entry.pause_time || 0 }} {{ $t('timetracking.minutes') }}</td>
              <td>{{ entry.duration_in_minutes }} {{ $t('timetracking.minutes') }}</td>
              <td>
                <button @click="deleteEntry(entry.id)" class="btn btn-danger btn-sm">{{ $t('common.delete') }}</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
  
  
  <script>
export default {
  data() {
    return {
      isTracking: false,
      startTime: null,
      endTime: null,
      elapsedTime: 0,
      timer: null,
      trackingStopped: false,
      manualStartTime: null,
      unsavedEntries: [],
      trackingEntry: {
        description: '',
        entityType: 'Auftrag',
        entityId: '',
        pauseTime: 0
      },
      manualEntry: {
        description: '',
        entityType: 'Auftrag',
        entityId: '',
        startTime: '',
        endTime: '',
        pauseTime: 0
      },
      savedEntries: [],
      companies: [],
      contacts: [],
      orders: [],
      loadingCompanies: false,
      loadingContacts: false,
      loadingOrders: false,
    };
  },
  mounted() {
    this.loadSavedEntries();
    this.fetchData();

    const savedStartTime = localStorage.getItem('startTime');
    const unsavedEntries = JSON.parse(localStorage.getItem('unsavedEntries') || '[]');

    if (savedStartTime) {
      this.startTime = new Date(savedStartTime);
      if (!isNaN(this.startTime.getTime())) {
        this.startTracking(true);
      }
    }

    if (unsavedEntries.length > 0) {
      this.unsavedEntries = unsavedEntries.map(entry => ({
        ...entry,
        startTime: new Date(entry.startTime),
        endTime: entry.endTime ? new Date(entry.endTime) : null
      }));
    }
  },
  methods: {
    async fetchData(retryCount = 0) {
      const token = localStorage.getItem('authToken');
      this.loadingCompanies = true;
      this.loadingContacts = true;
      this.loadingOrders = true;

      try {
        const [companiesRes, contactsRes, ordersRes] = await Promise.all([
          fetch('https://kompaktcrm.de/beta/companies_api.php', {
            headers: { 'Authorization': `Bearer ${token}` }
          }),
          fetch('https://kompaktcrm.de/beta/contacts_api.php', {
            headers: { 'Authorization': `Bearer ${token}` }
          }),
          fetch('https://kompaktcrm.de/beta/orders_api.php', {
            headers: { 'Authorization': `Bearer ${token}` }
          })
        ]);

        const companies = companiesRes.ok ? await companiesRes.json() : null;
        const contacts = contactsRes.ok ? await contactsRes.json() : null;
        const orders = ordersRes.ok ? await ordersRes.json() : null;

        if (companies && contacts && orders) {
          this.companies = companies;
          this.contacts = contacts;
          this.orders = orders;

          this.loadingCompanies = false;
          this.loadingContacts = false;
          this.loadingOrders = false;
        } else {
          throw new Error('Fehler beim Laden der Daten');
        }
      } catch (error) {
        console.error('Fehler beim Laden der Daten:', error);
        if (retryCount < 3) {
          setTimeout(() => {
            this.fetchData(retryCount + 1);
          }, 5000);
        }
      }
    },
    loadSavedEntries() {
      const token = localStorage.getItem('authToken');
      fetch(`https://kompaktcrm.de/beta/timetracking_api.php`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => response.json())
        .then(data => {
          this.savedEntries = data.map(entry => ({
            ...entry,
            startTime: new Date(entry.start_time),
            endTime: new Date(entry.end_time),
            elapsedTime: new Date(entry.end_time) - new Date(entry.start_time)
          }));
        })
        .catch(error => console.error('Fehler beim Laden der Zeiteinträge:', error));
    },
    setStartTimeToNow() {
      this.manualStartTime = this.getCurrentDateTimeLocal();
    },
    exportTimes() {
      this.$router.push({ name: 'TimeFilter' });
    },
    getCurrentDateTimeLocal() {
      const now = new Date();
      const offset = now.getTimezoneOffset();
      const localISOTime = new Date(now.getTime() - offset * 60 * 1000).toISOString().slice(0, -1);
      return localISOTime;
    },
    startTracking(resume = false) {
      if (resume && this.startTime) {
        // Der Startzeitpunkt wurde bereits aus dem localStorage geladen und wird fortgesetzt
      } else if (this.manualStartTime) {
        this.startTime = new Date(this.manualStartTime);
      } else {
        this.startTime = new Date(); // Setze den aktuellen Zeitpunkt
      }

      localStorage.setItem('startTime', this.startTime);
      this.isTracking = true;
      this.trackingStopped = false;
      this.timer = setInterval(() => {
        if (this.startTime) {
          this.elapsedTime = Date.now() - this.startTime.getTime();
        }
      }, 1000);
    },
    stopTracking() {
      this.isTracking = false;
      clearInterval(this.timer);
      this.endTime = new Date();
      this.trackingStopped = true;
      localStorage.removeItem('startTime');

      const newEntry = {
        description: '',
        entityType: 'Auftrag',
        entityId: '',
        startTime: this.startTime,
        endTime: this.endTime,
        elapsedTime: this.elapsedTime,
        pauseTime: 0
      };

      this.unsavedEntries.push(newEntry);
      localStorage.setItem('unsavedEntries', JSON.stringify(this.unsavedEntries));
    },
    formatTime(ms) {
      const totalSeconds = Math.floor(ms / 1000);
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;
      return `${this.padZero(hours)}:${this.padZero(minutes)}:${this.padZero(seconds)}`;
    },
    padZero(value) {
      return String(value).padStart(2, '0');
    },
    formatDateTime(datetime) {
      return new Date(datetime).toLocaleString();
    },
    saveTrackingEntry(index) {
      const token = localStorage.getItem('authToken');
      const entry = this.unsavedEntries[index];
      const payload = {
        action: 'saveEntry',
        id: entry.id || null,
        description: entry.description,
        entity_type: entry.entityType,
        entity_id: entry.entityId,
        pause_time: entry.pauseTime,
        start_time: entry.startTime.toISOString(),
        end_time: entry.endTime.toISOString()
      };

      fetch(`https://kompaktcrm.de/beta/timetracking_api.php`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      })
        .then(response => response.json())
        .then(result => {
          if (result.success) {
            if (!entry.id) {
              entry.id = result.id;
            }
            this.savedEntries.push(entry);
            this.unsavedEntries.splice(index, 1);
            localStorage.setItem('unsavedEntries', JSON.stringify(this.unsavedEntries));
          } else {
            alert('Fehler: ' + result.error);
          }
        })
        .catch(error => console.error('Fehler beim Speichern des Zeiteintrags:', error));
    },
    discardTrackingEntry(index) {
      const token = localStorage.getItem('authToken');
      const entry = this.unsavedEntries[index];


            this.unsavedEntries.splice(index, 1);
            localStorage.setItem('unsavedEntries', JSON.stringify(this.unsavedEntries));
         
    },
    addManualEntry() {
      const token = localStorage.getItem('authToken');
      const startTime = new Date(this.manualEntry.startTime);
      const endTime = new Date(this.manualEntry.endTime);
      const elapsedTime = endTime - startTime;

      const payload = {
        action: 'saveEntry',
        id: null,
        description: this.manualEntry.description,
        entity_type: this.manualEntry.entityType,
        entity_id: this.manualEntry.entityId,
        pause_time: this.manualEntry.pauseTime,
        start_time: startTime.toISOString(),
        end_time: endTime.toISOString()
      };

      fetch(`https://kompaktcrm.de/beta/timetracking_api.php`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      })
        .then(response => response.json())
        .then(result => {
          if (result.success) {
            this.savedEntries.push({
              id: result.id,
              description: this.manualEntry.description,
              entityType: this.manualEntry.entityType,
              entityId: this.manualEntry.entityId,
              startTime,
              endTime,
              elapsedTime,
              pauseTime: this.manualEntry.pauseTime
            });

            this.manualEntry = {
              description: '',
              entityType: 'Auftrag',
              entityId: '',
              startTime: '',
              endTime: '',
              pauseTime: 0
            };
          } else {
            alert('Fehler: ' + result.error);
          }
        })
        .catch(error => console.error('Fehler beim Hinzufügen des manuellen Zeiteintrags:', error));
    },
    deleteEntry(entryId) {
      const token = localStorage.getItem('authToken');

      fetch(`https://kompaktcrm.de/beta/timetracking_api.php?id=${entryId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => response.json())
        .then(result => {
          if (result.success) {
            this.loadSavedEntries(); // Liste der gespeicherten Einträge neu laden
          } else {
            alert('Fehler: ' + result.error);
          }
        })
        .catch(error => console.error('Fehler beim Löschen des Zeiteintrags:', error));
    }
  }
};
</script>

  
<style scoped>
h2 {
  font-weight: bold;
}
/* Timetracking container */
.timetracking-container {
  background-color: #f9f9f9;
  padding: 40px 20px;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  margin-bottom: 20px;
}

/* Title */
.timetracking-title {
  margin-bottom: 20px;
  font-weight: bold;
  color: #007bff;
}

/* Current tracking section */
.current-tracking {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.tracking-active {
  color: #28a745;
}

.tracking-inactive {
  color: #dc3545;
}

/* Tracking summary */
.tracking-summary {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}

/* Manual entry form */
.manual-entry-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.manual-entry-form .form-label {
  font-weight: bold;
  color: #333;
}

.manual-entry-form .form-control,
.manual-entry-form .form-select {
  border-radius: 5px;
  border: 1px solid #ced4da;
}

.manual-entry-form button[type="submit"] {
  width: 100%;
}

/* Tracked entries table */
.tracked-entries {
  margin-top: 40px;
}

.table {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.table thead {
  background-color: #007bff;
  color: #fff;
}

.table th,
.table td {
  padding: 15px;
  text-align: center;
  vertical-align: middle;
}

.table-hover tbody tr:hover {
  background-color: #f1f1f1;
}
</style>
