<template>
  <div class="container mt-5">
    <h2 class="text-primary mb-4">{{ $t('company_create.title_edit') }}</h2>
    <form @submit.prevent="updateCompany" class="card p-4 shadow-sm">
      
      <!-- Basisinformationen -->
      <h3>{{ $t('company_create.basic_information') }}</h3>
      <div class="row mb-3">
        <div class="col-md-6">
          <label for="name" class="form-label">{{ $t('company_create.name') }}</label>
          <input v-model="company.name" type="text" id="name" class="form-control" required>
        </div>
        <div class="col-md-6">
          <label for="registration_number" class="form-label">{{ $t('company_create.registration_number') }}</label>
          <input v-model="company.registration_number" type="text" id="registration_number" class="form-control">
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <label for="vat_number" class="form-label">{{ $t('company_create.vat_number') }}</label>
          <input v-model="company.vat_number" type="text" id="vat_number" class="form-control">
        </div>
        <div class="col-md-6">
          <label for="industry" class="form-label">{{ $t('company_create.industry') }}</label>
          <select v-model="company.industry" id="industry" class="form-control">
            <option value="IT">{{ $t('industries.it') }}</option>
            <option value="Finanzen">{{ $t('industries.finance') }}</option>
            <option value="Gesundheitswesen">{{ $t('industries.healthcare') }}</option>
            <option value="Bildung">{{ $t('industries.education') }}</option>
            <option value="Produktion">{{ $t('industries.manufacturing') }}</option>
            <option value="Handel">{{ $t('industries.trade') }}</option>
            <option value="Beratung">{{ $t('industries.consulting') }}</option>
            <option value="Transport & Logistik">{{ $t('industries.transportation') }}</option>
            <option value="Energie">{{ $t('industries.energy') }}</option>
            <option value="Bauwesen">{{ $t('industries.construction') }}</option>
            <option value="Marketing & Werbung">{{ $t('industries.marketing') }}</option>
            <option value="Immobilien">{{ $t('industries.real_estate') }}</option>
            <option value="Recht">{{ $t('industries.law') }}</option>
            <option value="Personalwesen">{{ $t('industries.hr') }}</option>
            <option value="Kunst & Unterhaltung">{{ $t('industries.entertainment') }}</option>
            <option value="Tourismus & Gastgewerbe">{{ $t('industries.hospitality') }}</option>
            <option value="Lebensmittel & Getränke">{{ $t('industries.food_beverage') }}</option>
            <option value="Landwirtschaft">{{ $t('industries.agriculture') }}</option>
            <option value="Umwelt">{{ $t('industries.environment') }}</option>
            <option value="Medien">{{ $t('industries.media') }}</option>
            <option value="Chemie">{{ $t('industries.chemicals') }}</option>
            <option value="Pharma">{{ $t('industries.pharmaceuticals') }}</option>
            <option value="Telekommunikation">{{ $t('industries.telecommunications') }}</option>
            <option value="Luft- und Raumfahrt">{{ $t('industries.aerospace') }}</option>
            <option value="Automobilindustrie">{{ $t('industries.automotive') }}</option>
            <option value="Elektrotechnik">{{ $t('industries.electrical_engineering') }}</option>
            <option value="Maschinenbau">{{ $t('industries.mechanical_engineering') }}</option>
            <option value="Verlagswesen">{{ $t('industries.publishing') }}</option>
            <option value="Öffentlicher Dienst">{{ $t('industries.public_service') }}</option>
            <option value="Sicherheitsdienstleistungen">{{ $t('industries.security_services') }}</option>
            <option value="Versicherungen">{{ $t('industries.insurance') }}</option>
            <option value="Bildung & Schulungen">{{ $t('industries.training_education') }}</option>
            <option value="Eventmanagement">{{ $t('industries.event_management') }}</option>
            <option value="Mode & Textilien">{{ $t('industries.fashion_textiles') }}</option>
            <option value="Metallindustrie">{{ $t('industries.metal_industry') }}</option>
            <option value="Holzverarbeitung">{{ $t('industries.wood_processing') }}</option>
            <option value="Kunststoffindustrie">{{ $t('industries.plastics_industry') }}</option>
            <option value="Papierindustrie">{{ $t('industries.paper_industry') }}</option>
            <option value="Bergbau">{{ $t('industries.mining') }}</option>
            <option value="Wasserwirtschaft">{{ $t('industries.water_management') }}</option>
            <option value="Abfallwirtschaft">{{ $t('industries.waste_management') }}</option>
            <option value="Biotechnologie">{{ $t('industries.biotechnology') }}</option>
            <option value="Lebensmittelproduktion">{{ $t('industries.food_production') }}</option>
            <option value="Sport & Freizeit">{{ $t('industries.sports_recreation') }}</option>
            <option value="Verbraucherdienstleistungen">{{ $t('industries.consumer_services') }}</option>
            <option value="Großhandel">{{ $t('industries.wholesale') }}</option>
            <option value="Einzelhandel">{{ $t('industries.retail') }}</option>
            <option value="Spielwarenindustrie">{{ $t('industries.toy_industry') }}</option>
            <option value="Optik & Fototechnik">{{ $t('industries.optics_photography') }}</option>
            <option value="Kosmetik & Körperpflege">{{ $t('industries.cosmetics_personal_care') }}</option>
            <option value="Wellness & Fitness">{{ $t('industries.wellness_fitness') }}</option>
            <option value="Andere/Sonstige">{{ $t('industries.other') }}</option>
          </select>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <label for="founding_date" class="form-label">{{ $t('company_create.founding_date') }}</label>
          <input v-model="company.founding_date" type="date" id="founding_date" class="form-control">
        </div>
        <div class="col-md-6">
          <label for="company_size" class="form-label">{{ $t('company_create.company_size') }}</label>
          <input v-model="company.company_size" type="text" id="company_size" class="form-control">
        </div>
      </div>

      <!-- Kontaktinformationen -->
      <h3>{{ $t('company_create.contact_information') }}</h3>
      <div class="mb-3">
        <label for="address" class="form-label">{{ $t('company_create.address') }}</label>
        <textarea v-model="company.address" id="address" class="form-control"></textarea>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <label for="address_additional" class="form-label">{{ $t('company_create.address_additional') }}</label>
          <input v-model="company.address_additional" type="text" id="address_additional" class="form-control">
        </div>
        <div class="col-md-6">
          <label for="state" class="form-label">{{ $t('company_create.state') }}</label>
          <input v-model="company.state" type="text" id="state" class="form-control">
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <label for="postal_code" class="form-label">{{ $t('company_create.postal_code') }}</label>
          <input v-model="company.postal_code" type="text" id="postal_code" class="form-control">
        </div>
        <div class="col-md-6">
          <label for="city" class="form-label">{{ $t('company_create.city') }}</label>
          <input v-model="company.city" type="text" id="city" class="form-control">
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <label for="country" class="form-label">{{ $t('company_create.country') }}</label>
          <input v-model="company.country" type="text" id="country" class="form-control">
        </div>
        <div class="col-md-6">
          <label for="phone" class="form-label">{{ $t('company_create.phone') }}</label>
          <input v-model="company.phone" type="text" id="phone" class="form-control">
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <label for="email" class="form-label">{{ $t('company_create.email') }}</label>
          <input v-model="company.email" type="email" id="email" class="form-control">
        </div>
        <div class="col-md-6">
          <label for="website" class="form-label">{{ $t('company_create.website') }}</label>
          <input v-model="company.website" type="text" id="website" class="form-control">
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <label for="linkedin_page" class="form-label">{{ $t('company_create.linkedin_page') }}</label>
          <input v-model="company.linkedin_page" type="text" id="linkedin_page" class="form-control">
        </div>
      </div>

      <!-- Finanzielle Informationen -->
      <h3>{{ $t('company_create.financial_information') }}</h3>
      <div class="row mb-3">
        <div class="col-md-6">
          <label for="bank_details" class="form-label">{{ $t('company_create.bank_details') }}</label>
          <input v-model="company.bank_details" type="text" id="bank_details" class="form-control">
        </div>
        <div class="col-md-6">
          <label for="payment_terms" class="form-label">{{ $t('company_create.payment_terms') }}</label>
          <input v-model="company.payment_terms" type="text" id="payment_terms" class="form-control">
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <label for="delivery_terms" class="form-label">{{ $t('company_create.delivery_terms') }}</label>
          <input v-model="company.delivery_terms" type="text" id="delivery_terms" class="form-control">
        </div>
        <div class="col-md-6">
          <label for="annual_revenue" class="form-label">{{ $t('company_create.annual_revenue') }}</label>
          <input v-model="company.annual_revenue" type="text" id="annual_revenue" class="form-control">
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <label for="profit_margin" class="form-label">{{ $t('company_create.profit_margin') }}</label>
          <input v-model="company.profit_margin" type="text" id="profit_margin" class="form-control">
        </div>
        <div class="col-md-6">
          <label for="project_budget" class="form-label">{{ $t('company_create.project_budget') }}</label>
          <input v-model="company.project_budget" type="text" id="project_budget" class="form-control">
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <label for="funding_sources" class="form-label">{{ $t('company_create.funding_sources') }}</label>
          <input v-model="company.funding_sources" type="text" id="funding_sources" class="form-control">
        </div>
        <div class="col-md-6">
          <label for="credit_rating" class="form-label">{{ $t('company_create.credit_rating') }}</label>
          <input v-model="company.credit_rating" type="text" id="credit_rating" class="form-control">
        </div>
      </div>

      <!-- Unternehmensinformationen -->
      <h3>{{ $t('company_create.company_information') }}</h3>
      <div class="row mb-3">
        <div class="col-md-6">
          <label for="owners" class="form-label">{{ $t('company_create.owners') }}</label>
          <input v-model="company.owners" type="text" id="owners" class="form-control">
        </div>
        <div class="col-md-6">
          <label for="holdings" class="form-label">{{ $t('company_create.holdings') }}</label>
          <input v-model="company.holdings" type="text" id="holdings" class="form-control">
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <label for="company_structure" class="form-label">{{ $t('company_create.company_structure') }}</label>
          <textarea v-model="company.company_structure" id="company_structure" class="form-control"></textarea>
        </div>
        <div class="col-md-6">
          <label for="mission_statement" class="form-label">{{ $t('company_create.mission_statement') }}</label>
          <textarea v-model="company.mission_statement" id="mission_statement" class="form-control"></textarea>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <label for="vision_statement" class="form-label">{{ $t('company_create.vision_statement') }}</label>
          <textarea v-model="company.vision_statement" id="vision_statement" class="form-control"></textarea>
        </div>
        <div class="col-md-6">
          <label for="main_competitors" class="form-label">{{ $t('company_create.main_competitors') }}</label>
          <textarea v-model="company.main_competitors" id="main_competitors" class="form-control"></textarea>
        </div>
      </div>
      <div class="mb-3">
        <label for="competitive_advantages" class="form-label">{{ $t('company_create.competitive_advantages') }}</label>
        <textarea v-model="company.competitive_advantages" id="competitive_advantages" class="form-control"></textarea>
      </div>

      <!-- Abschluss -->
      <div class="mt-4">
        <button type="submit" class="btn btn-success me-2">{{ $t('company_create.save') }}</button>
        <button type="button" class="btn btn-secondary" @click="goBack">{{ $t('company_create.cancel') }}</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      company: {
        name: '',
        registration_number: '',
        vat_number: '',
        industry: '',
        address: '',
        address_additional: '',
        postal_code: '',
        city: '',
        state: '',
        country: '',
        phone: '',
        email: '',
        website: '',
        linkedin_page: '',
        founding_date: '',
        company_size: '',
        bank_details: '',
        payment_terms: '',
        delivery_terms: '',
        annual_revenue: '',
        profit_margin: '',
        project_budget: '',
        funding_sources: '',
        credit_rating: '',
        owners: '',
        holdings: '',
        company_structure: '',
        mission_statement: '',
        vision_statement: '',
        main_competitors: '',
        competitive_advantages: '',
        company_references: '',
      },
    };
  },
  methods: {
    async fetchCompany() {
      const token = localStorage.getItem('authToken');
      try {
        const response = await fetch(`https://kompaktcrm.de/beta/companies_api.php?id=${this.$route.params.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (data.error) {
          console.error('Fehler beim Laden der Firma:', data.error);
        } else {
          this.company = data;
        }
      } catch (error) {
        console.error('Fehler beim Laden der Firma:', error);
      }
    },
    async updateCompany() {
      const token = localStorage.getItem('authToken');
      try {
        const response = await fetch('https://kompaktcrm.de/beta/companies_api.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(this.company),
        });
        const data = await response.json();
        if (data.success) {
          this.$router.push({ name: 'CompanyList' });
        } else {
          console.error('Fehler beim Aktualisieren der Firma:', data.error);
        }
      } catch (error) {
        console.error('Fehler beim Aktualisieren der Firma:', error);
      }
    },
    goBack() {
      this.$router.push({ name: 'CompanyList' });
    },
  },
  mounted() {
    this.fetchCompany();
  },
};
</script>

<style scoped>
.card {
  border-radius: 10px;
  background-color: #f8f9fa;
}

h2, h4, h3 {
  font-weight: bold;
}

.form-label {
  font-weight: 600;
}

textarea {
  resize: none;
}

button {
  width: 150px;
}
</style>
