<template>
    <div class="container mt-5">
      <h2 class="text-primary mb-4 text-center">Upgrade Your Experience</h2>
      <p class="text-secondary mb-4 text-center">
        All plans include time tracking, unlimited tasks, checklists, and notes, as well as multilingual support (DE, EN, ES, FR).
      </p>
      <p class="text-info mb-5 text-center">
        Get more features and power with our upgraded plans!
      </p>
  
      <div class="row justify-content-center">
        <div 
          class="col-md-3 mb-4" 
          v-for="(plan, index) in plans" 
          :key="plan.name"
          :class="{'recommended-plan': plan.recommended}"
        >
          <div class="card shadow-sm h-100">
            <div class="card-body d-flex flex-column">
              <h5 class="card-title text-center text-primary">{{ plan.name }}</h5>
              <h6 class="card-subtitle text-center mb-2 text-muted">{{ plan.price }}</h6>
  
              <ul class="list-unstyled mt-auto">
                <li><strong>Contacts:</strong> {{ plan.contacts }}</li>
                <li><strong>Companies:</strong> {{ plan.companies }}</li>
                <li><strong>Storage:</strong> {{ plan.storage }}</li>
                <li><strong>Invoices:</strong> {{ plan.invoices }}</li>
              </ul>
  
              <button 
                class="btn btn-primary mt-auto w-100" 
                @click="selectPlan(plan)"
                :class="{'btn-recommended': plan.recommended}"
              >
                {{ plan.buttonText }}
              </button>
            </div>
            <div v-if="plan.recommended" class="recommended-ribbon">Recommended</div>
          </div>
        </div>
      </div>
  
      <div class="text-center mt-5">
        <p class="text-muted">Not sure? Start with our Free Starter Plan and upgrade anytime!</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        plans: [
          {
            name: 'Starter Plan (Free)',
            price: '0 € / Month',
            contacts: 'Up to 25',
            companies: 'Up to 10',
            storage: '1 GB',
            invoices: 'Up to 2',
            buttonText: 'Get Started',
            recommended: false,
          },
          {
            name: 'Pro Plan',
            price: '3,95 € / Month',
            contacts: 'Up to 250',
            companies: 'Up to 100',
            storage: '100 GB',
            invoices: 'Up to 20',
            buttonText: 'Upgrade Now',
            recommended: false,  // This plan is recommended
          },
          {
            name: 'Business Plan',
            price: '9,95 € / Month',
            contacts: 'Up to 1000',
            companies: 'Up to 500',
            storage: '500 GB',
            invoices: 'Up to 50',
            buttonText: 'Upgrade Now',
            recommended: true,
          },
          {
            name: 'Enterprise Plan',
            price: '24,95 € / Month',
            contacts: 'Unlimited',
            companies: 'Unlimited',
            storage: '2 TB',
            invoices: 'Up to 500',
            buttonText: 'Upgrade Now',
            recommended: false,
          },
        ],
      };
    },
    methods: {
      selectPlan(plan) {
        alert(`You have selected the ${plan.name}.`);
      },
    },
  };
  </script>
  
  <style scoped>
  /* Container styles */
  .container {
    background-color: #f8f9fa;
    padding: 40px 20px;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  }
  
  /* Title styles */
  h2 {
    font-weight: bold;
    color: #007bff;
  }
  
  /* Subtitle styles */
  p.text-secondary {
    font-size: 1rem;
  }
  
  /* Recommended plan styles */
  .recommended-plan {
    position: relative;
    border: 2px solid #007bff;
    border-radius: 10px;
    background-color: #e6f7ff;
  }
  
  .recommended-ribbon {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #007bff;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
    transform: rotate(45deg);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  /* Button styles */
  .btn-recommended {
    background-color: #28a745;
    border-color: #28a745;
  }
  
  .btn-recommended:hover {
    background-color: #218838;
    border-color: #1e7e34;
  }
  
  .btn-primary {
    font-weight: bold;
    padding: 10px;
  }
  
  /* Mobile responsive styles */
  @media (max-width: 768px) {
    .row {
      flex-direction: column;
    }
  }
  </style>
  